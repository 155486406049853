import { EyeOutlined } from "@ant-design/icons";
import {
  Button,
  Carousel,
  Image,
  Input,
  Popover,
  Result,
  Segmented,
  Select,
  Switch,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  AllMarketPlace,
  DefaultPerPage,
  MarketplaceFlag,
  checkPermission,
  numberformat,
  timeSince,
} from "../../../config";
import { Wrapper } from "./style";
import { TableLoading } from "../../../components/table-animation";
import { GlobalContext } from "../../../commonContext";
import Pagination from "../../../components/pagination";
import { Icon } from "@iconify/react/dist/iconify.js";

const CatalogueByCountry = (props) => {
  const {
    GetCatalogListAction,
    GetCatalogListAmazonAction,
    fakeActionCataLogCountry,

    SalesChannelListAction,
    fakeActionCatalog,
  } = props;
  const contextValue = useContext(GlobalContext);
  const [filters, setFilters] = useState({
    asin: null,
    status: null,
  });
  const [list, setList] = useState({});
  const [listLoading, setListLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [tableLoading, setTableLoading] = useState(true);
  const [tableList, setTableList] = useState([]);

  const [viewType, setViewType] = useState("list");

  const UpdateCatalogConfigResponse = useSelector(
    (state) => state.CataLogCountry.GetCatalogListResponse || {}
  );
  const GetCatalogListAmazonResponse = useSelector(
    (state) => state.CataLogCountry.GetCatalogListAmazonResponse || {}
  );
  const contentStyle = {
    margin: 0,
    height: "50px",
    color: "#fff",

    textAlign: "center",
    background: "#364d79",
  };
  useEffect(() => {
    if (GetCatalogListAmazonResponse?.status === true) {
      setTotalPage(
        GetCatalogListAmazonResponse?.data?.pagination?.totalCount || 0
      );
      setTableList(GetCatalogListAmazonResponse?.data?.records || []);
      setTableLoading(false);
      fakeActionCataLogCountry("GetCatalogListAmazonResponse");
    } else if (GetCatalogListAmazonResponse?.status === false) {
      setTableLoading(false);
      setTableList([]);
      setTotalPage(0);
      fakeActionCataLogCountry("GetCatalogListAmazonResponse");
    }
  }, [GetCatalogListAmazonResponse]);

  useEffect(() => {
    if (UpdateCatalogConfigResponse?.status === true) {
      setViewType("amazon");
      message.destroy();
      setList(UpdateCatalogConfigResponse?.data || {});
      setListLoading(false);
      fakeActionCataLogCountry("UpdateCatalogConfigResponse");
    } else if (UpdateCatalogConfigResponse?.status === false) {
      message.destroy();
      setList({});
      setListLoading(false);
      fakeActionCataLogCountry("UpdateCatalogConfigResponse");
    }
  }, [UpdateCatalogConfigResponse]);

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
  });

  const [selectedFilter, setSelectedFilter] = useState([]);
  const [filterData, setFilterData] = useState({});

  const SalesChannelListResponse = useSelector(
    (state) => state.Catalog.SalesChannelListResponse || {}
  );
  useEffect(() => {
    if (SalesChannelListResponse?.status === true) {
      // setMarketplaceList(SalesChannelListResponse?.data?.records || []);
      if (SalesChannelListResponse?.data?.records?.length > 0) {
        const salesList = SalesChannelListResponse?.data?.records?.reduce(
          (acc, e) => {
            const regionIndex = acc.findIndex(
              (r) => r.label === e.region_label
            );
            if (e?.default_marketplace === 1) {
              setMarketplaceSelected({
                marketplace: e?.marketplace,
                marketplace_id: e?.marketplace_id,
                region: e?.region,
                sales_channel: e?.sales_channel,
                account_type: e?.account_type,
              });
              setTableLoading(true);
              GetCatalogListAmazonAction({
                page: page,
                perPage: pageSize,
                ...filterData,
                ...{
                  account_type: e?.account_type,
                  marketplace: e?.marketplace,
                  marketplace_id: e?.marketplace_id,
                  region: e?.region,
                  sales_channel: e?.sales_channel,
                },
                ...filters,
              });
            }
            if (regionIndex === -1) {
              acc.push({
                label: e.region_label,
                options: [
                  {
                    label: (
                      <>
                        <img
                          src={`/domainImage/${e?.marketplace_flag}.png`}
                          style={{ height: "20px" }}
                          loading="lazy"
                          className="me-3"
                          onError={(e) => {
                            e.target.src = "/media/domainImage/red-flag.png";
                          }}
                        />
                        {e?.region_label === "Ecommerce"
                          ? "Ecommerce"
                          : `Amazon.${e?.account_type || ""}.${
                              e?.short_name || ""
                            }`}
                      </>
                    ),
                    region: e.region,
                    marketplace_id: e?.marketplace_id,
                    account_type: e?.account_type,
                    value: e?.marketplace_id,
                    marketplace: e?.marketplace,
                    sales_channel: e?.sales_channel,
                  },
                ],
              });
            } else {
              acc[regionIndex].options.push({
                label: (
                  <>
                    <img
                      src={`/domainImage/${e?.marketplace_flag}.png`}
                      style={{ height: "20px" }}
                      loading="lazy"
                      className="me-3"
                      onError={(e) => {
                        e.target.src = "/media/domainImage/red-flag.png";
                      }}
                    />
                    {`Amazon.${e?.account_type || ""}.${e?.short_name || ""}`}
                  </>
                ),
                value: e?.marketplace_id,
                marketplace_id: e?.marketplace_id,
                region: e.region,
                marketplace: e?.marketplace,
                account_type: e?.account_type,
                sales_channel: e?.sales_channel,
              });
            }
            return acc;
          },
          []
        );

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);
        setMarketplaceLoading(false);
        setMarketplaceList([AllMarketPlace, ...uniqueList]);
      } else {
        setMarketplaceLoading(false);
        setMarketplaceList([]);
      }
      setMarketplaceLoading(false);
      fakeActionCatalog("SalesChannelListResponse");
    } else if (SalesChannelListResponse?.status === false) {
      setMarketplaceLoading(false);
      setMarketplaceList([]);
      setListLoading(false);
      setList([]);
      fakeActionCatalog("SalesChannelListResponse");
    }
  }, [SalesChannelListResponse]);

  useEffect(() => {
    // setTableLoading(true);
    // setTableList([]);
    // GetCatalogListAmazonAction({
    //   page: page,
    //   perPage: pageSize,
    //   ...filters,
    //   ...filterData,
    //   ...marketplaceSelected,
    // });

    setMarketplaceList([]);
    setMarketplaceLoading(true);
    SalesChannelListAction();

    return () => {};
  }, []);

  const getMessage = (amazon, local, row) => {
    if (1 === 1) return <></>;
    return (
      <div>
        {amazon === local ? (
          "✅"
        ) : (
          <Popover
            placement="right"
            content={
              <div
                style={{
                  maxWidth: "300px",
                  maxHeight: "200px",
                  overflow: "auto",
                }}
              >
                {local || "-"}
              </div>
            }
          >
            <span style={{ cursor: "pointer" }}>⚠️</span>
          </Popover>
        )}
      </div>
    );
  };

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id",
    // },
    // {
    //   title: "Main Image URL",
    //   dataIndex: "main_image_url",
    //   key: "main_image_url",
    // },
    {
      title: "Parent SKU",
      dataIndex: "sku",
      key: "sku",
      render: (e, row) => {
        return (
          <div className="d-flex align-items-center">
            <Tag color="cyan">{e}</Tag>{" "}
            {getMessage(e, row?.manual_data?.["sku"])}{" "}
          </div>
        );
      },
    },
    // {
    //   title: "Parent ASIN",
    //   dataIndex: "asin",
    //   key: "asin",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Tag
    //           onClick={() => {
    //             message.destroy();
    //             message.loading("Loading...", 0);
    //             setFilters({ ...filters, asin: e });
    //             setListLoading(true);
    //             GetCatalogListAction({
    //               ...filters,
    //               asin: e,
    //               sales_channel: marketplaceSelected?.sales_channel,
    //             });
    //           }}
    //           color="geekblue"
    //           style={{ cursor: "pointer" }}
    //         >
    //           {e}
    //         </Tag>
    //         {getMessage(e, row?.manual_data?.["asin"])}{" "}
    //       </div>
    //     );
    //   },
    // },

    {
      title: "Main Image",
      width: 100,
      render: (text) => {
        return (
          <div style={{ width: "90px", height: "90px" }}>
            <div>
              <img
                style={{
                  width: "90px",
                  height: "90px",
                  objectFit: "contain",
                }}
                onError={(e) =>
                  (e.target.src =
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png")
                }
                src={text?.main_image_url}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: "Product Title",
      dataIndex: "product_name",
      key: "product_name",
      width: 200,
      render: (e) => {
        return <div className="two-line">{e}</div>;
      },
    },

    {
      title: "Category",
      dataIndex: "category",
      key: "category",

      render: (e, row) => {
        return (
          <div className="d-flex align-items-center">
            <Tag
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "260px",
              }}
              color="purple"
            >
              {e}
            </Tag>
            {getMessage(e, row?.manual_data?.["category"])}
          </div>
        );
      },
    },
    {
      title: "Sub-Category",
      dataIndex: "sub_category",
      key: "sub_category",

      render: (e, row) => {
        return (
          <div className="d-flex align-items-center">
            <Tag
              color="purple"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "260px",
              }}
            >
              {e}
            </Tag>
            {getMessage(e, row?.manual_data?.["sub_category"])}
          </div>
        );
      },
    },
    {
      title: "Quality",
      dataIndex: "quality",
      key: "quality",
      render: (e) => {
        return <>{e || 0}</>;
      },
    },
    // {
    //   title: "Account Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Tag color="green">{e}</Tag>{" "}
    //         {getMessage(e, row?.manual_data?.["status"])}
    //       </div>
    //     );
    //   },
    // },

    // {
    //   title: "EAN",
    //   dataIndex: "ean",
    //   key: "ean",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Tag color="geekblue">{e}</Tag>{" "}
    //         {getMessage(e, row?.manual_data?.["ean"])}{" "}
    //       </div>
    //     );
    //   },
    // },

    // {
    //   title: "Brand",
    //   dataIndex: "brand",
    //   key: "brand",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Tag color="cyan">{e}</Tag>{" "}
    //         {getMessage(e, row?.manual_data?.["brand"])}{" "}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Product Name",
    //   dataIndex: "product_name",
    //   key: "product_name",
    //   width: 250,
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Popover
    //           placement="right"
    //           content={
    //             <div
    //               style={{
    //                 maxWidth: "300px",
    //                 maxHeight: "200px",
    //                 overflow: "auto",
    //               }}
    //             >
    //               {e}
    //             </div>
    //           }
    //         >
    //           <div className="product_description">{e || ""}</div>
    //         </Popover>
    //         {getMessage(e, row?.manual_data?.["product_name"])}{" "}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Price",
    //   dataIndex: "price",
    //   key: "price",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span> {getMessage(e, row?.manual_data?.["price"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Product Cost",
    //   dataIndex: "product_cost",
    //   key: "product_cost",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span> {getMessage(e, row?.manual_data?.["product_cost"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Parent SKU",
    //   dataIndex: "parent_sku",
    //   key: "parent_sku",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span> {getMessage(e, row?.manual_data?.["parent_sku"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Relationship Type",
    //   dataIndex: "relationship_type",
    //   key: "relationship_type",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span>{" "}
    //         {getMessage(e, row?.manual_data?.["relationship_type"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Variation Theme",
    //   dataIndex: "variation_theme",
    //   key: "variation_theme",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span>{" "}
    //         {getMessage(e, row?.manual_data?.["variation_theme"])}
    //       </div>
    //     );
    //   },
    // },

    // {
    //   title: "Product Description",
    //   dataIndex: "product_description",
    //   key: "product_description",
    //   width: 200,
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Popover
    //           placement="right"
    //           content={
    //             <div
    //               style={{
    //                 maxWidth: "300px",
    //                 maxHeight: "200px",
    //                 overflow: "auto",
    //               }}
    //             >
    //               {e}
    //             </div>
    //           }
    //         >
    //           <div className="product_description">{e || ""}</div>
    //         </Popover>
    //         {getMessage(e, row?.manual_data?.["product_description"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Bullet Point 1",
    //   dataIndex: "bullet_point_1",
    //   key: "bullet_point_1",
    //   width: 150,
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Popover
    //           placement="right"
    //           content={
    //             <div
    //               style={{
    //                 maxWidth: "300px",
    //                 maxHeight: "200px",
    //                 overflow: "auto",
    //               }}
    //             >
    //               {e}
    //             </div>
    //           }
    //         >
    //           <div className="product_description">{e || ""}</div>
    //         </Popover>
    //         {getMessage(e, row?.manual_data?.["bullet_point_1"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Bullet Point 2",
    //   dataIndex: "bullet_point_2",
    //   key: "bullet_point_2",
    //   width: 150,
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Popover
    //           placement="right"
    //           content={
    //             <div
    //               style={{
    //                 maxWidth: "300px",
    //                 maxHeight: "200px",
    //                 overflow: "auto",
    //               }}
    //             >
    //               {e}
    //             </div>
    //           }
    //         >
    //           <div className="product_description">{e || ""}</div>
    //         </Popover>
    //         {getMessage(e, row?.manual_data?.["bullet_point_2"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Bullet Point 3",
    //   dataIndex: "bullet_point_3",
    //   key: "bullet_point_3",
    //   width: 150,
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Popover
    //           placement="right"
    //           content={
    //             <div
    //               style={{
    //                 maxWidth: "300px",
    //                 maxHeight: "200px",
    //                 overflow: "auto",
    //               }}
    //             >
    //               {e}
    //             </div>
    //           }
    //         >
    //           <div className="product_description">{e || ""}</div>
    //         </Popover>
    //         {getMessage(e, row?.manual_data?.["bullet_point_3"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Bullet Point 4",
    //   dataIndex: "bullet_point_4",
    //   key: "bullet_point_4",
    //   width: 150,
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Popover
    //           placement="right"
    //           content={
    //             <div
    //               style={{
    //                 maxWidth: "300px",
    //                 maxHeight: "200px",
    //                 overflow: "auto",
    //               }}
    //             >
    //               {e}
    //             </div>
    //           }
    //         >
    //           <div className="product_description">{e || ""}</div>
    //         </Popover>
    //         {getMessage(e, row?.manual_data?.["bullet_point_4"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Bullet Point 5",
    //   dataIndex: "bullet_point_5",
    //   key: "bullet_point_5",
    //   width: 150,
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Popover
    //           placement="right"
    //           content={
    //             <div
    //               style={{
    //                 maxWidth: "300px",
    //                 maxHeight: "200px",
    //                 overflow: "auto",
    //               }}
    //             >
    //               {e}
    //             </div>
    //           }
    //         >
    //           <div className="product_description">{e || ""}</div>
    //         </Popover>
    //         {getMessage(e, row?.manual_data?.["bullet_point_5"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Style Name",
    //   dataIndex: "style_name",
    //   key: "style_name",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span> {getMessage(e, row?.manual_data?.["style_name"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Color Name",
    //   dataIndex: "color_name",
    //   key: "color_name",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span> {getMessage(e, row?.manual_data?.["color_name"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Size Name",
    //   dataIndex: "size_name",
    //   key: "size_name",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span> {getMessage(e, row?.manual_data?.["size_name"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Material Name",
    //   dataIndex: "material_name",
    //   key: "material_name",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span>{" "}
    //         {getMessage(e, row?.manual_data?.["material_name"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Flavor Name",
    //   dataIndex: "flavor_name",
    //   key: "flavor_name",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span> {getMessage(e, row?.manual_data?.["flavor_name"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Website Shipping Weight",
    //   dataIndex: "website_shipping_weight",
    //   key: "website_shipping_weight",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{numberformat(e)}</span>{" "}
    //         {getMessage(e, row?.manual_data?.["website_shipping_weight"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Item Length",
    //   dataIndex: "item_length",
    //   key: "item_length",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{numberformat(e)}</span>{" "}
    //         {getMessage(e, row?.manual_data?.["item_length"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Item Width",
    //   dataIndex: "item_width",
    //   key: "item_width",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{numberformat(e)}</span>{" "}
    //         {getMessage(e, row?.manual_data?.["item_width"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Item Height",
    //   dataIndex: "item_height",
    //   key: "item_height",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{numberformat(e)}</span>{" "}
    //         {getMessage(e, row?.manual_data?.["item_height"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Volume/Capacity Name",
    //   dataIndex: "volume_capacity_name",
    //   key: "volume_capacity_name",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span>{" "}
    //         {getMessage(e, row?.manual_data?.["volume_capacity_name"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Ingredients",
    //   dataIndex: "ingredients",
    //   key: "ingredients",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span> {getMessage(e, row?.manual_data?.["ingredients"])}
    //       </div>
    //     );
    //   },
    // },

    // {
    //   title: "Manufacturer",
    //   dataIndex: "manufacturer",
    //   key: "manufacturer",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span> {getMessage(e, row?.manual_data?.["manufacturer"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Marketplace ID",
    //   dataIndex: "marketplace_id",
    //   key: "marketplace_id",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span>{" "}
    //         {getMessage(e, row?.manual_data?.["marketplace_id"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Created By",
    //   dataIndex: "created_by",
    //   key: "created_by",
    // },
    // {
    //   title: "Updated By",
    //   dataIndex: "updated_by",
    //   key: "updated_by",
    // },
    // {
    //   title: "Created At",
    //   align: "left",
    //   render: (text) => {
    //     return (
    //       <div>
    //         <span>
    //           {moment(new Date(text.created_at * 1000)).format(
    //             "MM/DD/YYYY hh:mm A"
    //           )}
    //         </span>
    //         <br />
    //         <span className="timeStampColor">
    //           ({timeSince(text.created_at)})
    //         </span>
    //       </div>
    //     );
    //   },
    // },

    {
      title: "Updated At",
      align: "left",
      render: (text) => {
        return (
          <div>
            <span>
              {moment(new Date(parseInt(text.updated_at) * 1000)).format(
                "MM/DD/YYYY hh:mm A"
              )}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(parseInt(text.updated_at))})
            </span>
          </div>
        );
      },
    },
    // {
    //   title: "Action",

    //   align: "center",
    //   render: (e) => {
    //     return (
    //       <div
    //         style={{ cursor: "pointer" }}
    //         onClick={() => {
    //           message.destroy();
    //           message.loading("Loading...", 0);
    //           setFilters({ ...filters, asin: e?.asin });
    //           setListLoading(true);
    //           GetCatalogListAction({
    //             ...filters,
    //             sales_channel: marketplaceSelected?.sales_channel,
    //             asin: e?.asin,
    //           });
    //         }}
    //       >
    //         <EyeOutlined />
    //       </div>
    //     );
    //   },
    // },
  ];
  const onPageNo = (e) => {
    setPage(e);
    setTableLoading(true);
    setTableList([]);

    GetCatalogListAmazonAction({
      page: e,
      perPage: pageSize,
      ...filterData,
      ...marketplaceSelected,
      ...filters,
    });
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);

    setTableLoading(true);
    setTableList([]);
    GetCatalogListAmazonAction({
      page: 1,
      perPage: e,
      ...filters,
      ...filterData,
    });
  };
  const columnsChild = [
    {
      title: "SKU",
      width: 140,
      render: (_, __, i) => {
        return <Tag color="cyan-inverse">{_?.sku || "-"}</Tag>;
      },
    },
    {
      title: "ASIN",

      width: 140,

      render: (_, __, i) => (
        <Button type="dashed">
          <Tooltip title="Click to view details">
            <span
              onClick={() => {
                message.destroy();
                message.loading("Loading...", 0);
                setFilters({ ...filters, asin: _?.asin });
                setListLoading(true);
                GetCatalogListAction({
                  ...filters,
                  sales_channel: marketplaceSelected?.sales_channel,
                  asin: _?.asin,
                });
              }}
              color="blue-inverse"
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "#0958d9",
              }}
            >
              {_?.asin || "-"}
            </span>
          </Tooltip>
        </Button>
      ),
    },
    // {
    //   title: "ASIN",
    //   dataIndex: "asin",
    //   key: "asin",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Tag
    //           onClick={() => {
    //             message.destroy();
    //             message.loading("Loading...", 0);
    //             setFilters({ ...filters, asin: e });
    //             setListLoading(true);
    //             GetCatalogListAction({
    //               ...filters,
    //               asin: e,
    //             });
    //           }}
    //           color="blue-inverse"
    //           style={{ cursor: "pointer" }}
    //         >
    //           {e}
    //         </Tag>
    //         {getMessage(e, row?.manual_data?.["asin"])}{" "}
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Image",
      width: 100,
      render: (text) => {
        return (
          <div style={{ width: "90px", height: "90px" }}>
            <div>
              <img
                style={{
                  width: "90px",
                  height: "90px",
                  objectFit: "contain",
                }}
                onError={(e) =>
                  (e.target.src =
                    "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png")
                }
                src={text?.main_image_url}
              />
            </div>
          </div>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (e, row) => {
        return (
          <div className="d-flex align-items-center">
            <Tag color="purple">{e}</Tag>
            {getMessage(e, row?.manual_data?.["category"])}
          </div>
        );
      },
    },
    {
      title: "Sub-Category",
      dataIndex: "sub_category",
      key: "sub_category",
      render: (e, row) => {
        return (
          <div className="d-flex align-items-center">
            <Tag color="purple">{e}</Tag>
            {getMessage(e, row?.manual_data?.["sub_category"])}
          </div>
        );
      },
    },
    {
      title: "Quality",
      dataIndex: "quality",
      key: "quality",
      render: (e) => {
        return <>{e || 0}</>;
      },
    },
    // {
    //   title: "Account Status",
    //   dataIndex: "status",
    //   key: "status",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Tag color="green">{e}</Tag>{" "}
    //         {getMessage(e, row?.manual_data?.["status"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "SKU",
    //   dataIndex: "sku",
    //   key: "sku",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Tag color="lime">{e}</Tag>{" "}
    //         {getMessage(e, row?.manual_data?.["sku"])}{" "}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "EAN",
    //   dataIndex: "ean",
    //   key: "ean",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Tag color="geekblue">{e}</Tag>{" "}
    //         {getMessage(e, row?.manual_data?.["ean"])}{" "}
    //       </div>
    //     );
    //   },
    // },

    // {
    //   title: "Brand",
    //   dataIndex: "brand",
    //   key: "brand",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Tag color="cyan">{e}</Tag>{" "}
    //         {getMessage(e, row?.manual_data?.["brand"])}{" "}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Product Name",
    //   dataIndex: "product_name",
    //   key: "product_name",
    //   width: 250,
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Popover
    //           placement="right"
    //           content={
    //             <div
    //               style={{
    //                 maxWidth: "300px",
    //                 maxHeight: "200px",
    //                 overflow: "auto",
    //               }}
    //             >
    //               {e}
    //             </div>
    //           }
    //         >
    //           <div className="product_description">{e || ""}</div>
    //         </Popover>
    //         {getMessage(e, row?.manual_data?.["product_name"])}{" "}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Price",
    //   dataIndex: "price",
    //   key: "price",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span> {getMessage(e, row?.manual_data?.["price"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Product Cost",
    //   dataIndex: "product_cost",
    //   key: "product_cost",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span> {getMessage(e, row?.manual_data?.["product_cost"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Parent SKU",
    //   dataIndex: "parent_sku",
    //   key: "parent_sku",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span> {getMessage(e, row?.manual_data?.["parent_sku"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Relationship Type",
    //   dataIndex: "relationship_type",
    //   key: "relationship_type",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span>{" "}
    //         {getMessage(e, row?.manual_data?.["relationship_type"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Variation Theme",
    //   dataIndex: "variation_theme",
    //   key: "variation_theme",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span>{" "}
    //         {getMessage(e, row?.manual_data?.["variation_theme"])}
    //       </div>
    //     );
    //   },
    // },

    // {
    //   title: "Product Description",
    //   dataIndex: "product_description",
    //   key: "product_description",
    //   width: 200,
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Popover
    //           placement="right"
    //           content={
    //             <div
    //               style={{
    //                 maxWidth: "300px",
    //                 maxHeight: "200px",
    //                 overflow: "auto",
    //               }}
    //             >
    //               {e}
    //             </div>
    //           }
    //         >
    //           <div className="product_description">{e || ""}</div>
    //         </Popover>
    //         {getMessage(e, row?.manual_data?.["product_description"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Bullet Point 1",
    //   dataIndex: "bullet_point_1",
    //   key: "bullet_point_1",
    //   width: 150,
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Popover
    //           placement="right"
    //           content={
    //             <div
    //               style={{
    //                 maxWidth: "300px",
    //                 maxHeight: "200px",
    //                 overflow: "auto",
    //               }}
    //             >
    //               {e}
    //             </div>
    //           }
    //         >
    //           <div className="product_description">{e || ""}</div>
    //         </Popover>
    //         {getMessage(e, row?.manual_data?.["bullet_point_1"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Bullet Point 2",
    //   dataIndex: "bullet_point_2",
    //   key: "bullet_point_2",
    //   width: 150,
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Popover
    //           placement="right"
    //           content={
    //             <div
    //               style={{
    //                 maxWidth: "300px",
    //                 maxHeight: "200px",
    //                 overflow: "auto",
    //               }}
    //             >
    //               {e}
    //             </div>
    //           }
    //         >
    //           <div className="product_description">{e || ""}</div>
    //         </Popover>
    //         {getMessage(e, row?.manual_data?.["bullet_point_2"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Bullet Point 3",
    //   dataIndex: "bullet_point_3",
    //   key: "bullet_point_3",
    //   width: 150,
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Popover
    //           placement="right"
    //           content={
    //             <div
    //               style={{
    //                 maxWidth: "300px",
    //                 maxHeight: "200px",
    //                 overflow: "auto",
    //               }}
    //             >
    //               {e}
    //             </div>
    //           }
    //         >
    //           <div className="product_description">{e || ""}</div>
    //         </Popover>
    //         {getMessage(e, row?.manual_data?.["bullet_point_3"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Bullet Point 4",
    //   dataIndex: "bullet_point_4",
    //   key: "bullet_point_4",
    //   width: 150,
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Popover
    //           placement="right"
    //           content={
    //             <div
    //               style={{
    //                 maxWidth: "300px",
    //                 maxHeight: "200px",
    //                 overflow: "auto",
    //               }}
    //             >
    //               {e}
    //             </div>
    //           }
    //         >
    //           <div className="product_description">{e || ""}</div>
    //         </Popover>
    //         {getMessage(e, row?.manual_data?.["bullet_point_4"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Bullet Point 5",
    //   dataIndex: "bullet_point_5",
    //   key: "bullet_point_5",
    //   width: 150,
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <Popover
    //           placement="right"
    //           content={
    //             <div
    //               style={{
    //                 maxWidth: "300px",
    //                 maxHeight: "200px",
    //                 overflow: "auto",
    //               }}
    //             >
    //               {e}
    //             </div>
    //           }
    //         >
    //           <div className="product_description">{e || ""}</div>
    //         </Popover>
    //         {getMessage(e, row?.manual_data?.["bullet_point_5"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Style Name",
    //   dataIndex: "style_name",
    //   key: "style_name",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span> {getMessage(e, row?.manual_data?.["style_name"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Color Name",
    //   dataIndex: "color_name",
    //   key: "color_name",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span> {getMessage(e, row?.manual_data?.["color_name"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Size Name",
    //   dataIndex: "size_name",
    //   key: "size_name",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span> {getMessage(e, row?.manual_data?.["size_name"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Material Name",
    //   dataIndex: "material_name",
    //   key: "material_name",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span>{" "}
    //         {getMessage(e, row?.manual_data?.["material_name"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Flavor Name",
    //   dataIndex: "flavor_name",
    //   key: "flavor_name",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span> {getMessage(e, row?.manual_data?.["flavor_name"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Website Shipping Weight",
    //   dataIndex: "website_shipping_weight",
    //   key: "website_shipping_weight",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{numberformat(e)}</span>{" "}
    //         {getMessage(e, row?.manual_data?.["website_shipping_weight"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Item Length",
    //   dataIndex: "item_length",
    //   key: "item_length",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{numberformat(e)}</span>{" "}
    //         {getMessage(e, row?.manual_data?.["item_length"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Item Width",
    //   dataIndex: "item_width",
    //   key: "item_width",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{numberformat(e)}</span>{" "}
    //         {getMessage(e, row?.manual_data?.["item_width"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Item Height",
    //   dataIndex: "item_height",
    //   key: "item_height",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{numberformat(e)}</span>{" "}
    //         {getMessage(e, row?.manual_data?.["item_height"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Volume/Capacity Name",
    //   dataIndex: "volume_capacity_name",
    //   key: "volume_capacity_name",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span>{" "}
    //         {getMessage(e, row?.manual_data?.["volume_capacity_name"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Ingredients",
    //   dataIndex: "ingredients",
    //   key: "ingredients",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span> {getMessage(e, row?.manual_data?.["ingredients"])}
    //       </div>
    //     );
    //   },
    // },

    // {
    //   title: "Manufacturer",
    //   dataIndex: "manufacturer",
    //   key: "manufacturer",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span> {getMessage(e, row?.manual_data?.["manufacturer"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Marketplace ID",
    //   dataIndex: "marketplace_id",
    //   key: "marketplace_id",
    //   render: (e, row) => {
    //     return (
    //       <div className="d-flex align-items-center">
    //         <span>{e}</span>{" "}
    //         {getMessage(e, row?.manual_data?.["marketplace_id"])}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Created By",
    //   dataIndex: "created_by",
    //   key: "created_by",
    // },
    // {
    //   title: "Updated By",
    //   dataIndex: "updated_by",
    //   key: "updated_by",
    // },
    // {
    //   title: "Created At",
    //   align: "left",
    //   render: (text) => {
    //     return (
    //       <div>
    //         <span>
    //           {moment(new Date(text.created_at * 1000)).format(
    //             "MM/DD/YYYY hh:mm A"
    //           )}
    //         </span>
    //         <br />
    //         <span className="timeStampColor">
    //           ({timeSince(text.created_at)})
    //         </span>
    //       </div>
    //     );
    //   },
    // },

    {
      title: "Updated At",
      align: "left",
      render: (text) => {
        return (
          <div>
            <span>
              {moment(new Date(parseInt(text.updated_at) * 1000)).format(
                "MM/DD/YYYY hh:mm A"
              )}
            </span>
            <br />
            <span className="timeStampColor">
              ({timeSince(parseInt(text.updated_at))})
            </span>
          </div>
        );
      },
    },
    // {
    //   title: "Action",

    //   align: "center",
    //   render: (e) => {
    //     return (
    //       <div
    //         style={{ cursor: "pointer" }}
    //         onClick={() => {
    //           message.destroy();
    //           message.loading("Loading...", 0);
    //           setFilters({ ...filters, asin: e?.asin });
    //           setListLoading(true);
    //           GetCatalogListAction({
    //             ...filters,
    //             asin: e?.asin,
    //           });
    //         }}
    //       >
    //         <EyeOutlined />
    //       </div>
    //     );
    //   },
    // },
  ];
  const formatData = (data) => {
    return data.map((item) => {
      const formattedItem = {};

      for (const key in item) {
        if (item[key] === null || item[key] === "") {
          formattedItem[key] = "-";
        } else {
          formattedItem[key] = item[key];
        }
      }

      return formattedItem;
    });
  };

  const expandedRowRender = (record) => {
    return (
      <Table
        dataSource={record?.manual_data || []}
        columns={columnsChild}
        pagination={false} // Remove pagination if you don't need it
        scroll={{ x: "max-content" }}
      />
    );
  };

  const filterTable = () => {
    setTableLoading(true);
    setTableList([]);
    GetCatalogListAmazonAction({
      page: page,
      perPage: pageSize,
      ...filters,
      ...filterData,
      ...marketplaceSelected,
    });
  };

  if (checkPermission(contextValue, "catalogue_by_country", "view")) {
    return (
      <Result
        status="403"
        title="403"
        message="Sorry, you are not authorized to access this page."
      />
    );
  }
  if (viewType === "list") {
    return (
      <Wrapper className="p-5 fadeInBottom">
        <div className="d-flex justify-content-end align-items-center flex-wrap mb-5 fix-over fix-over">
          <Segmented options={["FBA", "FBM"]} />
          {Object.keys(filterData)?.map((d, i) => {
            return (
              <Input
                size="large"
                className="w-150px ms-3"
                placeholder={`Select ${d}`}
                value={filterData?.[d]}
                onPressEnter={() => filterTable()}
                onChange={(e) => {
                  setFilterData({ ...filterData, [d]: e.target.value });
                }}
              />
            );
          })}
          <Select
            className="w-200px  ms-3"
            placeholder="Select filter type"
            size="large"
            maxTagCount={"responsive"}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(e) => {
              if (e?.length < selectedFilter?.length) {
                const filteredObject = Object.entries(filterData).reduce(
                  (newObj, [key, value]) => {
                    if (e.includes(key)) {
                      newObj[key] = value;
                    }
                    return newObj;
                  },
                  {}
                );

                setTableLoading(true);
                setTableList([]);
                GetCatalogListAmazonAction({
                  page: page,
                  perPage: pageSize,
                  ...filters,
                  ...filteredObject,
                  ...marketplaceSelected,
                });
              }
              setSelectedFilter(e);
              const nullObject = {};

              for (const key of e) {
                nullObject[key] = filterData?.[key] || null;
              }
              setFilterData(nullObject);
            }}
            value={selectedFilter}
            mode="multiple"
            options={[
              {
                label: "Category",
                value: "category",
              },
              {
                label: "Sub Category",
                value: "sub_category",
              },
              {
                label: "Product Name",
                value: "product_name",
              },
              {
                label: "Parent SKU",
                value: "parent_sku",
              },

              {
                label: "Parent ASIN",
                value: "parent_asin",
              },
              {
                label: "Brand",
                value: "brand",
              },
            ]}
          />
          <Select
            className="w-150px ms-3"
            placeholder="Select Status"
            getPopupContainer={(trigger) => trigger.parentNode}
            size="large"
            onChange={(e) => {
              setFilters({
                ...filters,
                status: e,
              });
              setTableLoading(true);
              setTableList([]);
              GetCatalogListAmazonAction({
                page: page,
                perPage: pageSize,
                ...filterData,
                ...marketplaceSelected,
                ...{
                  ...filters,
                  status: e,
                },
              });
            }}
            options={[
              {
                label: "All",
                value: null,
              },
              {
                label: "Active",
                value: "Active",
              },
              {
                label: "In Active",
                value: "Inactive",
              },
            ]}
          />
          <Select
            className="w-225px ms-3"
            size="large"
            allowClear
            getPopupContainer={(target) => target.parentNode}
            options={marketplaceList}
            onChange={(_, e) => {
              setMarketplaceSelected({
                marketplace: e?.marketplace,
                marketplace_id: e?.marketplace_id,
                region: e?.region,
                sales_channel: e?.sales_channel,
                account_type: e?.account_type,
              });
              setTableLoading(true);
              setTableList([]);
              GetCatalogListAmazonAction({
                page: page,
                perPage: pageSize,
                ...filterData,
                ...{
                  marketplace: e?.marketplace,
                  account_type: e?.account_type,
                  marketplace_id: e?.marketplace_id,
                  region: e?.region,
                  sales_channel: e?.sales_channel,
                },
                ...filters,
              });
            }}
            value={marketplaceSelected?.marketplace_id}
            loading={marketplaceLoading}
            // value={selectedMarketplace}
            placeholder="Select Marketplace"
          />
        </div>
        <div className="card">
          <div className="card-body">
            {tableLoading ? (
              <TableLoading
                id="test-table"
                columns={[1, 2, 3, 4, 5]}
                checkBoxVal={true}
                actions={[1, 2]}
              />
            ) : (
              <Table
                dataSource={formatData(tableList)?.map((d, i) => ({
                  ...d,
                  key: i,
                }))}
                loading={tableLoading}
                columns={columns}
                expandable={{
                  expandedRowRender,
                  expandIcon: ({ expanded, onExpand, record }) => (
                    <Button
                      className="cursor-pointer"
                      type="link"
                      onClick={(e) => {
                        onExpand(record);
                        e.stopPropagation();
                      }}
                    >
                      {expanded ? (
                        <Icon icon="ic:baseline-minus" width={20} />
                      ) : (
                        <Icon icon="ic:outline-plus" width={20} />
                      )}
                    </Button>
                  ),
                }}
                pagination={false} // Remove pagination if you don't need it
                scroll={{ x: "max-content" }}
              />
            )}
            <Pagination
              loading={tableLoading || tableList?.length === 0}
              pageSize={pageSize}
              pageSizeStatus={false}
              page={page}
              totalPage={totalPage}
              onPerPage={onPerPage}
              onPageNo={onPageNo}
            />
          </div>
        </div>
      </Wrapper>
    );
  }

  const listData = {
    main_image_url: "Main image",
    other_image_url: [
      "other_image_url_1",
      "other_image_url_2",
      "other_image_url_3",
      "other_image_url_4",
      "other_image_url_5",
      "other_image_url_6",
      "other_image_url_7",
    ],
    product_name: "Product name",
    // asin: "ASIN",
    // sku: "SKU",
    ean: "EAN",
    // brand: "Brand",

    status: "Status",
    price: "Price",
    // product_cost: "Product cost",
    parent_sku: "Parent SKU",
    relationship_type: "Relationship type",
    variation_theme: "Variation theme",

    product_description: "Product description",
    bullet_point_1: "Bullet Point 1",
    bullet_point_2: "Bullet Point 2",
    bullet_point_3: "Bullet Point 3",
    bullet_point_4: "Bullet Point 4",
    bullet_point_5: "Bullet Point 5",
    style_name: "Style name",
    color_name: "Color name",
    size_name: "Size name",
    material_name: "Material name",
    flavor_name: "Flavor name",
    website_shipping_weight: "Website shipping weight",
    item_length: "Item length",
    item_width: "Item width",
    item_height: "Item height",
    volume_capacity_name: "Volume capacity name",
    ingredients: "Ingredients",
    manufacturer: "Manufacturer",
    marketplace_id: "Marketplace Id",
    created_by: "Created by",
    updated_by: "Updated_by",
  };

  const AmazonData = list?.amazonData || {};
  const ManualData = list?.manualData || {};

  // console.log(
  //   checkPermission(contextValue, "catalogue_by_country", "view"),
  //   'checkPermission(contextValue, "catalogue_by_country", "view")'
  // );

  return (
    <Wrapper className="p-5 fadeInBottom">
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            <button
              className="btn btn-light-primary btn-icon"
              style={{ height: 40 }}
              onClick={() => {
                setFilters({ ...filters, asin: null });
                setViewType("list");
              }}
            >
              <i className="bi bi-arrow-left-short fs-1" />
            </button>
            <div className="row ms-5">
              <Tag color="blue-inverse" style={{ width: "fit-content" }}>
                ASIN:&nbsp;
                {ManualData?.asin || "-"}
              </Tag>
              <Tag color="cyan-inverse" style={{ width: "fit-content" }}>
                SKU:&nbsp;
                {ManualData?.sku || "-"}
              </Tag>
              <Tag color="gold-inverse" style={{ width: "fit-content" }}>
                Brand:&nbsp;
                {ManualData?.brand || "-"}
              </Tag>
              <Tag color="purple-inverse" style={{ width: "fit-content" }}>
                Category:&nbsp;
                {ManualData?.category || "-"}
              </Tag>
              <Tag color="purple" style={{ width: "fit-content" }}>
                Sub Category:&nbsp;
                {ManualData?.sub_category || "-"}
              </Tag>
            </div>
          </div>
          <div className="card-toolbar"></div>
        </div>
        <div className="card-body ">
          <div className="row header-view">
            <div className="col-2 ">Fields</div>
            <div className="col-10">
              <div className="row">
                <div className="col-6">Manual</div>
                <div className="col-6">Amazon</div>
              </div>
            </div>
          </div>

          {Object.entries(listData)?.map((d, i) => {
            if (d?.[0] === "created_by" || d?.[0] === "updated_by") {
              return;
            }

            return (
              <div
                className={`row ${
                  d?.[0] === "other_image_url"
                    ? "center-text"
                    : ManualData?.[d?.[0]] === AmazonData?.[d?.[0]]
                    ? "match"
                    : !ManualData?.[d?.[0]] && !AmazonData?.[d?.[0]]
                    ? "match"
                    : "not_match"
                }`}
                key={i}
              >
                <div className="col-2 ">
                  {d?.[0] === "other_image_url" ? "Other image" : d?.[1]}
                </div>
                <div className="col-10">
                  <div className="row make-card-row">
                    <div className="col-6 border-right">
                      {d?.[0] === "main_image_url" ? (
                        <Image
                          style={{
                            width: 150,
                            height: 150,
                            objectFit: "contain",
                            borderRadius: "10px",
                          }}
                          src={ManualData?.[d?.[0]]}
                          onError={(e) =>
                            (e.target.src =
                              "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png")
                          }
                        />
                      ) : "other_image_url" === d?.[0] ? (
                        <>
                          <Carousel>
                            {d?.[1]?.map((p, j) => (
                              <div key={j}>
                                <Image
                                  style={{
                                    backgroundColor:
                                      ManualData?.[p] === AmazonData?.[p]
                                        ? "#deedde"
                                        : "#ffeeee",
                                    border:
                                      ManualData?.[p] === AmazonData?.[p]
                                        ? "1px solid #4ad54a"
                                        : "1px solid #ffb0b0",
                                    borderRadius: "8px",
                                  }}
                                  src={ManualData?.[p]}
                                  onError={(e) =>
                                    (e.target.src =
                                      "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png")
                                  }
                                />
                              </div>
                            ))}
                          </Carousel>
                        </>
                      ) : (
                        ManualData?.[d?.[0]] || "-"
                      )}
                    </div>
                    <div className="col-6">
                      {d?.[0] === "main_image_url" ? (
                        <Image
                          style={{
                            width: 150,
                            height: 150,
                            objectFit: "contain",
                            borderRadius: "10px",
                          }}
                          onError={(e) =>
                            (e.target.src =
                              "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png")
                          }
                          src={AmazonData?.[d?.[0]]}
                        />
                      ) : "other_image_url" === d?.[0] ? (
                        <>
                          <Carousel>
                            {d?.[1]?.map((p, j) => (
                              <div key={j}>
                                <Image
                                  style={{
                                    backgroundColor:
                                      ManualData?.[p] === AmazonData?.[p]
                                        ? "#deedde"
                                        : "#ffeeee",
                                    border:
                                      ManualData?.[p] === AmazonData?.[p]
                                        ? "1px solid #4ad54a"
                                        : "1px solid #ffb0b0",
                                    borderRadius: "8px",
                                  }}
                                  src={AmazonData?.[p]}
                                  onError={(e) =>
                                    (e.target.src =
                                      "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d1/Image_not_available.png/640px-Image_not_available.png")
                                  }
                                />
                              </div>
                            ))}
                          </Carousel>
                        </>
                      ) : (
                        AmazonData?.[d?.[0]] || "-"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Wrapper>
  );
};

export default CatalogueByCountry;
