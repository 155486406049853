import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Segmented, Select, message } from "antd";
import { useSelector } from "react-redux";

const Create = ({
  isOpen,
  onClose,
  getList,
  AllAdminAAction,
  fakeActionManageUser,
  AddUserAction,
  selectedRow,
  UserUpdateAction,
}) => {
  const AllAdminAResponse = useSelector(
    (state) => state.User.AllAdminAResponse || {}
  );
  const AddUserLResponse = useSelector(
    (state) => state.User.AddUserLResponse || {}
  );
  const UserUpdateResponse = useSelector(
    (state) => state.User.UserUpdateResponse || {}
  );

  const [adminList, setAdminList] = useState([]);

  // useEffect(() => {
  //   if (AllAdminAResponse?.status === true) {
  //     setAdminList(
  //       Object?.entries(AllAdminAResponse?.data)?.map((d) => ({
  //         label: d?.[1],
  //         value: d?.[0],
  //       }))
  //     );
  //     fakeActionManageUser("AllAdminAResponse");
  //   } else if (AllAdminAResponse?.status === false) {
  //     fakeActionManageUser("AllAdminAResponse");
  //   }
  // }, [AllAdminAResponse]);

  useEffect(() => {
    if (AddUserLResponse?.status === true) {
      message.destroy();
      message.success(AddUserLResponse?.message);
      fakeActionManageUser("AddUserLResponse");
      getList();
      onClose();
    } else if (AddUserLResponse?.status === false) {
      message.destroy();

      message.error(
        Object.values(AddUserLResponse?.error || {})?.[0]?.[0] ||
          AddUserLResponse?.message
      );
      fakeActionManageUser("AddUserLResponse");
    }
  }, [AddUserLResponse]);
  useEffect(() => {
    if (UserUpdateResponse?.status === true) {
      message.destroy();
      message.success(UserUpdateResponse?.message);
      fakeActionManageUser("UserUpdateResponse");
      getList();
      onClose();
    } else if (UserUpdateResponse?.status === false) {
      message.destroy();

      message.error(
        Object.values(UserUpdateResponse?.error || {})?.[0]?.[0] ||
          UserUpdateResponse?.message
      );
      fakeActionManageUser("UserUpdateResponse");
    }
  }, [UserUpdateResponse]);

  useEffect(() => {
    // AllAdminAAction();
    return () => {};
  }, []);

  const onFinish = (values) => {
    message.destroy();
    message.loading("Loading...", 0);
    if (Object?.keys(selectedRow)?.length === 0) {
      AddUserAction({
        ...values,
        // user_admin_id: values?.user_admin_id?.join(","),
        rds_credential_id: 1,
        server_credential_id: 1,
      });
    } else {
      UserUpdateAction(selectedRow?.u_id, {
        ...values,
        // user_admin_id: values?.user_admin_id?.join(","),
      });
    }
    // onClose();
  };
  const phoneRegex = /^\d{10}$/;

  console.log(selectedRow, "selectedRow");

  return (
    <Modal
      title={Object?.keys(selectedRow)?.length === 0 ? "Add User" : "Edit User"}
      open={isOpen}
      onCancel={onClose}
      footer={null}
    >
      <Form
        name="basic"
        layout="vertical"
        initialValues={{
          remember: true,

          ...selectedRow,
          lqs_data_frequency: selectedRow?.lqs_data_frequency?.toString(),
          // user_admin_id: selectedRow?.admin?.map((d) => d?.admin_id),
        }}
        onFinish={onFinish}
      >
        <div
          style={{
            maxHeight: "calc(100vh - 320px)",
            overflow: "auto",
            overflowX: "hidden",
            paddingRight: "15px",
            marginBottom: "15px",
          }}
        >
          <div className="row">
            <div className="col-12">
              <Form.Item
                label="Name"
                name="u_name"
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input />
              </Form.Item>
            </div>
            {/* <div className="col-6">
              <Form.Item
                label="Admin"
                name="user_admin_id"
                rules={[{ required: true, message: "Please select admin!" }]}
              >
                <Select
                  mode={"multiple"}
                  maxTagCount="responsive"
                  options={adminList}
                  getPopupContainer={(target) => target.parentNode}
                />
              </Form.Item>
            </div> */}
          </div>

          <Form.Item
            label="Email"
            name="u_email"
            rules={[
              {
                required: true,

                message: "Please input your email!",
              },
              {
                type: "email",
                message: "Please enter valid email!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          {Object?.keys(selectedRow)?.length === 0 && (
            <>
              <Form.Item
                label="Password"
                name="u_password"
                rules={[
                  { required: true, message: "Please input your password!" },
                  {
                    min: 8,
                    message: "Password must be at least 8 characters",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                label="Confirm Password"
                name="u_confirm_password"
                rules={[
                  { required: true, message: "Please confirm your password!" },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("u_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject("The two passwords do not match!");
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
            </>
          )}
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please input your Buy Box Data Update Frequency!",
              },
            ]}
            label="Buy Box Data Update Frequency"
            name="lqs_data_frequency"
          >
            <Select
              options={[
                {
                  label: "Once every 12 hours",
                  value: "12",
                },
                {
                  label: "Once every 24 hours",
                  value: "24",
                },
                {
                  label: "Once every 48 hours",
                  value: "48",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            rules={[
              {
                pattern: phoneRegex,
                message: "Please enter a valid phone number!",
              },
            ]}
            label="Contact Number"
            name="u_contact_no"
          >
            <Input />
          </Form.Item>

          <Form.Item label="Address" name="u_address">
            <Input />
          </Form.Item>

          <Form.Item
            label="Reference Contact Name"
            name="u_reference_contact_name"
          >
            <Input />
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,

                message: "Please input your email!",
              },
              {
                type: "email",
                message: "Please enter valid email!",
              },
            ]}
            label="Reference Email"
            name="u_reference_email"
          >
            <Input />
          </Form.Item>

          <Form.Item label="Amazon Seller Name" name="u_amazon_seller_name">
            <Input />
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
              {
                type: "email",
                message: "Please enter valid email!",
              },
            ]}
            label="Amazon Seller Email"
            name="u_amazon_seller_email"
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item className="mb-0 d-flex align-items-center justify-content-end">
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Create;
