import {
  Button,
  Divider,
  Input,
  Modal,
  Select,
  Spin,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useState, createContext } from "react";
import { useSelector } from "react-redux";
import Icons from "../../../components/icons";
import NoData from "../../../components/no-data";
import Pagination from "../../../components/pagination";
import { LoadingOutlined } from "@ant-design/icons";
import {
  AllMarketPlace,
  DefaultPerPage,
  NumberWithCommas,
} from "../../../config";
import { Wrapper } from "./style";
import { Icon } from "@iconify/react/dist/iconify.js";
import dayjs from "dayjs";

const LQSHistory = ({
  visible,
  setVisible,
  historyList,
  onPageNo,
  onPerPage,
  page,
  setPage,
  totalPage,
  setTotalPage,
  setPageSize,
  pageSize,
}) => {
  const history_list = historyList?.map((d) => ({
    ...d,
    bqs: JSON.parse(d?.bqs),
    lqs: JSON.parse(d?.lqs),
    retail_readlines: JSON.parse(d?.retail_readlines),
  }));

  return (
    <Wrapper className="p-5 fadeInBottom">
      <div className="row mb-3" style={{ zoom: "96%" }}>
        <div className="col d-flex align-items-center">
          <svg
            width={20}
            onClick={() => setVisible(false)}
            height={20}
            style={{ marginRight: "10px", cursor: "pointer" }}
            fill="#000"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M11.009 5.325c.3.299.3.784 0 1.084l-4.825 4.824h13.483a.767.767 0 0 1 0 1.534H6.184l4.825 4.824a.767.767 0 0 1-1.084 1.084L3.79 12.543c-.3-.3-.3-.785 0-1.084l6.134-6.133c.299-.3.784-.3 1.084 0Z"
              clipRule="evenodd"
            />
          </svg>
          <span>Back to List</span>
        </div>
      </div>
      <div className="card_">
        <div className="card-body">
          {history_list?.map((cardData) => (
            <div className="row gap-3 mb-3 d-flex justify-content-start">
              <div className="col-auto d-grid align-items-center">
                <Tag color="blue">
                  Update of{" "}
                  {dayjs(cardData?.created_at * 1000).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </Tag>
                <span>Total Products: {cardData?.total_products || 0}</span>
              </div>
              {[
                { label: "LQS", value: "lqs", subValue: "lqs_status_count" },
                {
                  label: "Retail Readiness",
                  value: "retail_readlines",
                  subValue: "retail_readiness_status_count",
                },
                { label: "BQS", value: "bqs", subValue: "bqs_status_count" },
              ]?.map((d, i) => (
                <div key={i} className="col ms-3">
                  <div className="border  border-dashed border-gray-300  rounded px-7 py-3 make-box d-flex align-items-center">
                    <div
                      className=" label me-3"
                      style={{ maxWidth: "130px", minWidth: "100px" }}
                    >
                      <label className="text-gray-500 fs-7 fw-bold me-2 d-block lh-1 ">
                        {d?.label}
                      </label>
                      <span className="text-gray-800 fs-1 fw-bold">
                        {NumberWithCommas(
                          cardData?.[d?.value]?.[`${d?.value}_scores`]
                        )}
                      </span>
                    </div>
                    <div className=" d-flex flex-wrap align-items-center">
                      <Tag color="red" bordered={false}>
                        Critic:{" "}
                        {cardData?.[d?.value]?.[d?.subValue]?.Critic || 0}
                      </Tag>
                      <Tag color="orange" bordered={false}>
                        Warning:{" "}
                        {cardData?.[d?.value]?.[d?.subValue]?.Warning || 0}
                      </Tag>
                      <Tag color="blue" bordered={false}>
                        Basic: {cardData?.[d?.value]?.[d?.subValue]?.Basic || 0}
                      </Tag>
                      <Tag color="green" bordered={false}>
                        Good: {cardData?.[d?.value]?.[d?.subValue]?.Good || 0}
                      </Tag>
                    </div>
                  </div>
                </div>
              ))}
              <Divider />
            </div>
          ))}
          <Pagination
            loading={history_list?.length === 0}
            pageSize={pageSize}
            pageSizeStatus={false}
            page={page}
            totalPage={totalPage}
            onPerPage={onPerPage}
            onPageNo={onPageNo}
          />
        </div>
      </div>
    </Wrapper>
  );
};

const AmazonLqs = (props) => {
  const {
    SalesChannelListAction,
    fakeActionCatalog,
    GetListAmazonLQSHistoryAction,
    CreateAmazonLQSAction,
    GetListAmazonLQSAction,

    fakeActionAnalytics,
    GetCategoryAction,
    GetSubCategoryAction,
  } = props;
  const [modal, contextHolder] = Modal.useModal();
  const [categoryList, setCategoryList] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(true);

  const [selectedCategorys, setSelectedCategorys] = useState({
    category: null,
    subCategory: null,
  });

  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryLoading, setSubCategoryLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [historyList, setHistoryList] = useState([]);
  const [historyModal, setHistoryModal] = useState(false);
  const SalesChannelListResponse = useSelector(
    (state) => state.Catalog.SalesChannelListResponse || {}
  );
  const CatalogResponse = useSelector(
    (state) => state.Catalog.AmazonLQSListResponse || {}
  );
  const CreateAmazonLQSResponse = useSelector(
    (state) => state.Catalog.CreateAmazonLQSResponse || {}
  );
  const ListCatalogHistoryResponse = useSelector(
    (state) => state.Catalog.ListCatalogHistoryResponse || {}
  );

  const GetCategoryListResponse = useSelector(
    (state) => state.Analytics.GetCategoryListResponse || {}
  );
  const GetSubCategoryListResponse = useSelector(
    (state) => state.Analytics.GetSubCategoryListResponse || {}
  );

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
    account_type: null,
  });
  const [isSubmit, setIsSubmit] = useState(true);
  const [cardData, setCardData] = useState({
    lqs_scores: 0,
    retail_readiness_scores: 0,
    bqs_scores: 0,
    bqs_status_count: {
      Critic: 0,
      Basic: 0,
      Warning: 0,
      Good: 0,
    },
    lqs_status_count: {
      Critic: 0,
      Basic: 0,
      Warning: 0,
      Good: 0,
    },
    retail_readiness_status_count: {
      Critic: 0,
      Basic: 0,
      Warning: 0,
      Good: 0,
    },
  });

  const [listSortFilters, setListSortFilters] = useState({
    key: "",
    type: "",
  });
  const [lastUpdatedDate, setLastUpdatedDate] = useState("");

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [page_, setPage_] = useState(1);
  const [totalPage_, setTotalPage_] = useState(1);
  const [pageSize_, setPageSize_] = useState(DefaultPerPage);

  const [counts, setCounts] = useState({
    parent_count: 0,
    child_count: 0,
  });

  const [scroes, setScroes] = useState({
    title: "",
    data: {},
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (data) => {
    setScroes(data);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setScroes({ title: "", data: {} });
    setIsModalVisible(false);
  };

  const [sign, setSign] = useState("$");
  const [filters, setFilters] = useState({
    lqa_filter: null,
    filter_value: null,
  });
  const [dataGet, setDataGet] = useState(false);

  const onPageNo = (e) => {
    setPage(e);
    setLoading(true);
    GetListAmazonLQSAction({
      page: e,
      perPage: pageSize,
      sort: listSortFilters?.key,
      ...filters,
      ...marketplaceSelected,
      // ...filterData,
    });
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    GetListAmazonLQSAction({
      page: page,
      perPage: e,
      sort: listSortFilters?.key,
      ...marketplaceSelected,
      ...filters,
    });
  };

  const onPageNo_ = (e) => {
    setPage_(e);
    setHistoryLoading(true);
    GetListAmazonLQSHistoryAction({
      page: e,
      perPage: pageSize,
      sort: listSortFilters?.key,
      ...filters,
      ...marketplaceSelected,
    });
  };

  const onPerPage_ = (e) => {
    setPage_(1);
    setPageSize_(e);
    setHistoryLoading(true);
    GetListAmazonLQSHistoryAction({
      page: page,
      perPage: e,
      sort: listSortFilters?.key,
      ...marketplaceSelected,
      ...filters,
    });
  };

  useEffect(() => {
    if (CatalogResponse?.status === true) {
      if (CatalogResponse?.data?.file) {
        message.destroy();
        window.open(CatalogResponse?.data?.file);
        return;
      }
      setCounts(
        CatalogResponse?.data?.asin_count || { parent_count: 0, child_count: 0 }
      );
      setCardData(
        CatalogResponse?.data?.card || {
          lqs_scores: 0,
          retail_readiness_scores: 0,
          bqs_scores: 0,
          bqs_status_count: {
            Critic: 0,
            Basic: 0,
            Warning: 0,
            Good: 0,
          },
          lqs_status_count: {
            Critic: 0,
            Basic: 0,
            Warning: 0,
            Good: 0,
          },
          retail_readiness_status_count: {
            Critic: 0,
            Basic: 0,
            Warning: 0,
            Good: 0,
          },
        }
      );
      setDataGet(
        Object.keys(CatalogResponse?.data?.pendingLog || {})?.length !== 0
      );
      setLastUpdatedDate(CatalogResponse?.data?.lastLogTime || "");
      setSign(CatalogResponse?.data?.currency || "$");
      setTotalPage(CatalogResponse?.data?.pagination?.totalCount || 0);
      setList(CatalogResponse?.data?.records);
      setLoading(false);
      fakeActionCatalog("CatalogResponse");
    } else if (CatalogResponse?.status === false) {
      setList([]);
      setLoading(false);
      fakeActionCatalog("CatalogResponse");
    }
  }, [CatalogResponse]);
  useEffect(() => {
    if (CreateAmazonLQSResponse?.status === true) {
      setDataGet(true);
      fakeActionCatalog("CreateAmazonLQSResponse");
    } else if (CreateAmazonLQSResponse?.status === false) {
      setDataGet(false);
      message.destroy();
      message.warning(
        CreateAmazonLQSResponse?.data ||
          CreateAmazonLQSResponse?.message ||
          "Something went wrong"
      );
      fakeActionCatalog("CreateAmazonLQSResponse");
    }
  }, [CreateAmazonLQSResponse]);

  useEffect(() => {
    if (ListCatalogHistoryResponse?.status === true) {
      setHistoryList(ListCatalogHistoryResponse?.data?.records || []);
      setHistoryLoading(false);
      setHistoryModal(true);
      setTotalPage_(
        ListCatalogHistoryResponse?.data?.pagination?.totalCount || 0
      );
      fakeActionCatalog("ListCatalogHistoryResponse");
    } else if (ListCatalogHistoryResponse?.status === false) {
      setHistoryLoading(false);
      setHistoryList([]);
      setTotalPage_(0);
      setHistoryModal(false);
      fakeActionCatalog("ListCatalogHistoryResponse");
    }
  }, [ListCatalogHistoryResponse]);

  useEffect(() => {
    if (SalesChannelListResponse?.status === true) {
      if (SalesChannelListResponse?.data?.records?.length > 0) {
        const salesList = SalesChannelListResponse?.data?.records?.reduce(
          (acc, e) => {
            const regionIndex = acc.findIndex(
              (r) => r.label === e.region_label
            );

            if (regionIndex === -1) {
              acc.push({
                label: e.region_label,
                options: [
                  {
                    label: (
                      <>
                        <img
                          src={`/domainImage/${e?.marketplace_flag}.png`}
                          style={{ height: "20px" }}
                          loading="lazy"
                          className="me-3"
                          onError={(e) => {
                            e.target.src = "/media/domainImage/red-flag.png";
                          }}
                        />
                        {e?.region_label === "Ecommerce"
                          ? "Ecommerce"
                          : `Amazon.${e?.account_type || ""}.${
                              e?.short_name || ""
                            }`}
                        {/* {`Amazon.${e?.account_type || ''}.${e?.short_name || ''}`} */}
                      </>
                    ),
                    value: e.marketplace_id,
                    region: e.region,
                    marketplace_id: e?.marketplace_id,
                    marketplace: e?.marketplace,
                    sales_channel: e?.sales_channel,
                    account_type: e?.account_type,
                  },
                ],
              });
              if (e?.default_marketplace === 1) {
                GetCategoryAction({
                  region: e?.region,
                  sales_channel: e?.sales_channel,
                  account_type: e?.account_type,
                });
                GetSubCategoryAction({
                  region: e?.region,
                  sales_channel: e?.sales_channel,
                  account_type: e?.account_type,
                });
                setMarketplaceSelected({
                  marketplace: e?.marketplace,
                  marketplace_id: e.marketplace_id,
                  region: e.region,
                  sales_channel: e.sales_channel,
                  account_type: e?.account_type,
                });
                setLoading(true);
                GetListAmazonLQSAction({
                  page: page,
                  perPage: pageSize,
                  sort: listSortFilters?.key,
                  ...filters,
                  ...{
                    region: e?.region,
                    account_type: e?.account_type,
                    sales_channel: e?.sales_channel,
                  },
                });
              }
            } else {
              acc[regionIndex].options.push({
                label: (
                  <>
                    <img
                      src={`/domainImage/${e?.marketplace_flag}.png`}
                      style={{ height: "20px" }}
                      loading="lazy"
                      className="me-3"
                      onError={(e) => {
                        e.target.src = "/media/domainImage/red-flag.png";
                      }}
                    />
                    {`Amazon.${e?.account_type || ""}.${e?.short_name || ""}`}
                  </>
                ),
                value: e.marketplace_id,
                marketplace_id: e?.marketplace_id,
                region: e.region,
                marketplace: e?.marketplace,
                sales_channel: e?.sales_channel,
                account_type: e?.account_type,
              });
            }
            return acc;
          },
          []
        );

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);
        setMarketplaceLoading(false);
        setMarketplaceList([AllMarketPlace, ...uniqueList]);
      } else {
        setMarketplaceLoading(false);
        setMarketplaceList([]);
      }
      setMarketplaceLoading(false);
      fakeActionCatalog("SalesChannelListResponse");
    } else if (SalesChannelListResponse?.status === false) {
      setMarketplaceLoading(false);
      setMarketplaceList([]);

      fakeActionCatalog("SalesChannelListResponse");
    }
  }, [SalesChannelListResponse]);

  useEffect(() => {
    if (GetCategoryListResponse?.status === true) {
      setCategoryLoading(false);
      setCategoryList(
        GetCategoryListResponse?.data?.data?.map((d) => ({
          label: d?.category,
          value: d?.category,
        }))
      );
      fakeActionAnalytics("GetCategoryListResponse");
    } else if (GetCategoryListResponse?.status === false) {
      setCategoryList([]);
      setCategoryLoading(false);
      message.destroy();
      message.warning(GetCategoryListResponse?.message);
      fakeActionAnalytics("GetCategoryListResponse");
    }
  }, [GetCategoryListResponse]);
  useEffect(() => {
    if (GetSubCategoryListResponse?.status === true) {
      setSubCategoryLoading(false);
      setSubCategoryList(
        GetSubCategoryListResponse?.data?.data?.map((d) => ({
          label: d?.sub_category,
          value: d?.sub_category,
        }))
      );
      fakeActionAnalytics("GetSubCategoryListResponse");
    } else if (GetSubCategoryListResponse?.status === false) {
      setSubCategoryList([]);
      setSubCategoryLoading(false);
      message.destroy();
      message.warning(GetSubCategoryListResponse?.message);
      fakeActionAnalytics("GetSubCategoryListResponse");
    }
  }, [GetSubCategoryListResponse]);

  useEffect(() => {
    setMarketplaceList([]);
    setMarketplaceLoading(true);
    SalesChannelListAction();

    return () => {};
  }, []);

  const listExtraProps = (type) => {
    return {
      defaultSortOrder:
        type === listSortFilters?.key?.replace("-", "")
          ? listSortFilters.type
          : [],
      sorter: () => {},
    };
  };

  const handleOnChangeColumn = (_, __, sorter) => {
    const sort =
      sorter?.order === "ascend"
        ? sorter?.column?.key
        : sorter?.order === "descend"
        ? `-${sorter?.column?.key}`
        : "";

    setLoading(true);
    GetListAmazonLQSAction({
      page: page,
      perPage: pageSize,
      sort: sort,
      ...filters,
      ...marketplaceSelected,
    });

    // setTableData([]);
    // setTableLoading(true);
    // const obj = {
    //   sales_channel: marketplaceSelected?.sales_channel,
    //   region: marketplaceSelected?.region,
    //   date: dateFilter?.start + "+-+" + dateFilter?.end,
    //   category_filter: filters?.category_filter,
    //   advertising_filter: filters?.advertising_filter,
    //   category: filters?.category,
    //   sub_category: filters?.sub_category,
    //   pageSize: pageSize,
    //   page: page,
    //   account_type: filters?.account_type,
    //   sort,
    // };

    setListSortFilters({
      key: sort,
      type: sorter?.order || "",
    });
    // AnalyticsByTableAction(obj);
  };

  function swapCommasAndPeriods(input) {
    // Step 1: Replace commas with a temporary character
    let temp = input?.toString()?.replace(/,/g, "#");

    // Step 2: Replace periods with commas
    temp = temp.replace(/\./g, ",");

    // Step 3: Replace temporary character with periods
    let result = temp?.replace(/#/g, ".");

    return result;
  }

  const columns = [
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      render: (e) => {
        return (
          <Tag bordered={false} color="blue">
            {e}
          </Tag>
        );
      },
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      render: (e) => {
        return (
          <Tag color="cyan" bordered={false}>
            {e}
          </Tag>
        );
      },
    },
    {
      title: "Product Title",
      dataIndex: "product_title",
      key: "product_title",
      width: 400,
      render: (text) => <a>{text}</a>, // Example of custom rendering, e.g., making it a link
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      ...listExtraProps("category"),
    },
    {
      title: "Sub Category",
      dataIndex: "sub_category",
      key: "sub_category",
      ...listExtraProps("sub_category"),
    },
    {
      title: "LQS Value",
      dataIndex: "total_score_lqs",
      key: "total_score_lqs",
      ...listExtraProps("total_score_lqs"),

      render: (e, r) => {
        const findColors = {
          Critic: "red",
          Warning: "orange",
          Basic: "blue",
          Good: "green",
        };

        return (
          <div>
            <Tag bordered={false} color={findColors?.[r?.status_lqs]}>
              {swapCommasAndPeriods(e || 0)}
            </Tag>
            <span
              onClick={() => {
                showModal({
                  title: "LQS Value",
                  data: r?.score?.lqs,
                });
              }}
            >
              <Icons type="eye" />
            </span>
          </div>
        );
      },
    },
    {
      title: "Retail Readiness",
      dataIndex: "total_score_retail_readiness",
      key: "total_score_retail_readiness",
      ...listExtraProps("total_score_retail_readiness"),

      render: (e, r) => {
        const findColors = {
          Critic: "red",
          Warning: "orange",
          Basic: "blue",
          Good: "green",
        };

        return (
          <div>
            <Tag
              bordered={false}
              color={findColors?.[r?.status_retail_readiness]}
            >
              {swapCommasAndPeriods(e || 0)}
            </Tag>
            <span
              onClick={() => {
                showModal({
                  title: "Retail Readiness",
                  data: r?.score?.retail_readiness,
                });
              }}
            >
              <Icons type="eye" />
            </span>
          </div>
        );
      },
    },
    {
      title: "BQS",
      dataIndex: "total_score_bqs",
      key: "total_score_bqs",
      ...listExtraProps("total_score_bqs"),
      render: (e, r) => {
        const findColors = {
          Critic: "red",
          Warning: "orange",
          Basic: "blue",
          Good: "green",
        };

        return (
          <div>
            <Tag bordered={false} color={findColors?.[r?.status_bqs]}>
              {swapCommasAndPeriods(e || 0)}
            </Tag>

            <span
              onClick={() => {
                showModal({
                  title: "BQS",
                  data: r?.score?.bqs,
                });
              }}
            >
              <Icons type="eye" />
            </span>
          </div>
        );
      },
    },

    {
      title: "Quality Condition",
      dataIndex: "status_lqs",
      key: "status_lqs",

      render: (e) => {
        const findColors = {
          Critic: "red",
          Warning: "orange",
          Basic: "blue",
          Good: "green",
        };

        return (
          <Tag color={findColors?.[e]} bordered={false}>
            {e}
          </Tag>
        );
      },
    },
  ];

  const findTableData = {
    "LQS Value": {
      "Title length": "product_title_score",
      "Bullet point 1": "bullet_point_1_score",
      "Bullet point 2": "bullet_point_2_score",
      "Bullet point 3": "bullet_point_3_score",
      "Bullet point 4": "bullet_point_4_score",
      "Bullet point 5": "bullet_point_5_score",
      Description: "description_score",
      "Images count": "image_count_score",
    },
    "Retail Readiness": {
      "Reviews Number": "review_number_score",
      "Review Score": "review_score",
      "Buy box": "buy_box",
      Quantity: "quantity",
    },
    BQS: {
      "A+": "a_plus_score",
      "Brand Content": "brand_content_score",
      "Store Link+": "store_link_score",
      Video: "video_score",
    },
  };

  if (historyModal) {
    return (
      <LQSHistory
        historyList={historyList}
        visible={historyModal}
        onPageNo={onPageNo_}
        onPerPage={onPerPage_}
        setVisible={setHistoryModal}
        page={page_}
        setPage={setPage_}
        totalPage={totalPage_}
        setTotalPage={setTotalPage_}
        setPageSize={setPageSize_}
        pageSize={pageSize_}
      />
    );
  }
  return (
    <Wrapper className="p-5 fadeInBottom">
      <div className="d-flex justify-content-between align-items-center flex-wrap mb-5 fix-over">
        <div className="row mb-3">
          <div className="d-flex align-items-center">
            {lastUpdatedDate && (
              <Tag className="me-2" bordered={false} color="volcano">
                Last update: {lastUpdatedDate}
              </Tag>
            )}
            <Button type="dashed">
              <Spin
                indicator={<LoadingOutlined spin />}
                spinning={historyLoading}
              >
                <div
                  onClick={() => {
                    setHistoryLoading(true);
                    setHistoryList([]);
                    GetListAmazonLQSHistoryAction({
                      page: page_,
                      perPage: pageSize_,
                      ...filters,
                      ...marketplaceSelected,
                    });
                  }}
                  className="d-flex align-items-center cursor-pointer"
                >
                  {" "}
                  <Icon width={20} icon="ci:arrow-reload-02" />
                  Chronology
                </div>
              </Spin>
            </Button>
            {!dataGet ? (
              <>
                <Button
                  type="primary"
                  className="me-3 ms-3"
                  onClick={async () => {
                    const confirmed = await modal.confirm({
                      title: "Update Quality Check",
                      content: (
                        <>
                          Are you sure you want to get the data to be updated in
                          the system?
                        </>
                      ),
                    });
                    if (confirmed) {
                      setDataGet(true);
                      CreateAmazonLQSAction({
                        ...marketplaceSelected,
                        marketplace_id:
                          marketplaceSelected?.marketplace_id?.split("_")?.[0],
                      });
                    }
                    console.log("Confirmed: ", confirmed);
                  }}
                  size="small"
                >
                  Update Quality Check
                </Button>
                <span>
                  On clicking on the Update Quality Check button, it will
                  refresh the data from the Live API in few hours
                </span>
              </>
            ) : (
              <Button type="primary" className="me-3" loading size="large">
                Sync in progress
              </Button>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className=" fix-over flex-wrap d-flex align-items-center">
              <Select
                placeholder="Category"
                style={{ width: "150px" }}
                size="large"
                filterOption={(input, option) =>
                  option?.label.toLowerCase().includes(input.toLowerCase())
                }
                showSearch
                options={categoryList}
                allowClear
                loading={categoryLoading}
                value={selectedCategorys?.category || null}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(e) => {
                  GetListAmazonLQSAction({
                    page: page,
                    perPage: pageSize,
                    sort: listSortFilters?.key,
                    ...filters,
                    ...marketplaceSelected,
                    ...selectedCategorys,
                    category: e,
                  });
                  setSubCategoryLoading(true);
                  GetSubCategoryAction({
                    ...marketplaceSelected,
                    category: e,
                  });
                  setSelectedCategorys({
                    ...selectedCategorys,
                    subCategory: null,
                    category: e,
                  });
                }}
              />

              <Select
                placeholder="Sub Category"
                allowClear
                style={{ width: "175px" }}
                className="ms-3 me-3"
                size="large"
                options={subCategoryList}
                filterOption={(input, option) =>
                  option?.label.toLowerCase().includes(input.toLowerCase())
                }
                onDropdownVisibleChange={(e) => {
                  if (e && !selectedCategorys?.category) {
                    message.destroy();
                    message.warning('Please select "Category" first');
                  }
                }}
                showSearch
                loading={subCategoryLoading}
                value={selectedCategorys?.subCategory || null}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(e) => {
                  GetListAmazonLQSAction({
                    page: page,
                    perPage: pageSize,
                    sort: listSortFilters?.key,
                    ...filters,
                    ...marketplaceSelected,
                    ...selectedCategorys,
                    subCategory: e,
                  });
                  setSelectedCategorys({
                    ...selectedCategorys,
                    subCategory: e,
                  });
                }}
              />

              <Input
                placeholder="ASIN / SKU / Title"
                size="large"
                className="w-200px me-3 ms-4"
                onPressEnter={() => {
                  setLoading(true);
                  GetListAmazonLQSAction({
                    page: page,
                    perPage: pageSize,
                    ...filters,
                    ...marketplaceSelected,
                    sort: listSortFilters?.key,
                  });
                }}
                onChange={(e) => {
                  setFilters({ ...filters, filter_value: e.target.value });
                }}
                value={filters?.filter_value}
              />
              <Tooltip placement="top" title="LQS Filter by Status">
                <Select
                  size="large"
                  className="me-3 w-100px"
                  placeholder="Status"
                  getPopupContainer={(target) => target.parentNode}
                  onChange={(e) => {
                    setFilters({ ...filters, lqa_filter: e });
                    setLoading(true);
                    GetListAmazonLQSAction({
                      page: page,
                      perPage: pageSize,
                      lqa_filter: e,
                      ...marketplaceSelected,
                      sort: listSortFilters?.key,
                    });
                  }}
                  value={filters?.lqa_filter}
                  allowClear
                  options={[
                    {
                      label: (
                        <div className="d-flex justify-content-center">
                          <Tag color="red" bordered={false}>
                            Critic
                          </Tag>
                        </div>
                      ),
                      value: "0-20",
                    },
                    {
                      label: (
                        <div className="d-flex justify-content-center">
                          <Tag color="orange" bordered={false}>
                            Warning
                          </Tag>
                        </div>
                      ),
                      value: "21-40",
                    },
                    {
                      label: (
                        <div className="d-flex justify-content-center">
                          <Tag color="blue" bordered={false}>
                            Basic
                          </Tag>
                        </div>
                      ),
                      value: "41-50",
                    },
                    {
                      label: (
                        <div className="d-flex justify-content-center">
                          <Tag color="green" bordered={false}>
                            Good
                          </Tag>
                        </div>
                      ),
                      value: "51-100",
                    },
                  ]}
                />
              </Tooltip>
              <Select
                className="w-225px "
                size="large"
                allowClear
                getPopupContainer={(target) => target.parentNode}
                options={marketplaceList}
                onChange={(_, e) => {
                  setSubCategoryLoading(true);
                  GetSubCategoryAction({
                    region: e?.region,
                    sales_channel: e?.sales_channel,
                  });
                  setCategoryLoading(true);
                  GetCategoryAction({
                    region: e?.region,
                    sales_channel: e?.sales_channel,
                    account_type: e.account_type,
                  });
                  setMarketplaceSelected({
                    marketplace: e?.marketplace,
                    marketplace_id: e?.marketplace_id,
                    region: e?.region,
                    sales_channel: e?.sales_channel,
                    account_type: e?.account_type,
                  });
                  setLoading(true);
                  GetListAmazonLQSAction({
                    page: page,
                    perPage: pageSize,
                    sort: listSortFilters?.key,
                    ...filters,
                    ...{
                      account_type: e?.account_type,
                      region: e?.region,
                      sales_channel: e?.sales_channel,
                    },
                  });
                }}
                value={marketplaceSelected?.marketplace_id}
                loading={marketplaceLoading}
                placeholder="Select Marketplace"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row gap-3 mb-3 d-flex align-items-center justify-content-start">
        {[
          { label: "LQS", value: "lqs_scores", subValue: "lqs_status_count" },
          {
            label: "Retail Readiness",
            value: "retail_readiness_scores",
            subValue: "retail_readiness_status_count",
          },
          { label: "BQS", value: "bqs_scores", subValue: "bqs_status_count" },
        ]?.map((d, i) => (
          <div key={i} className="col ms-3">
            <div className="border  border-dashed border-gray-300  rounded px-7 py-3 make-box d-flex align-items-center">
              <div
                className=" label me-3"
                style={{ maxWidth: "130px", minWidth: "100px" }}
              >
                <label className="text-gray-500 fs-7 fw-bold me-2 d-block lh-1 ">
                  {d?.label}
                </label>
                <span className="text-gray-800 fs-1 fw-bold">
                  {NumberWithCommas(cardData?.[d?.value])}
                </span>
              </div>
              <div className=" d-flex flex-wrap align-items-center">
                <Tag color="red" bordered={false}>
                  Critic: {cardData?.[d?.subValue]?.Critic || 0}
                </Tag>
                <Tag color="orange" bordered={false}>
                  Warning: {cardData?.[d?.subValue]?.Warning || 0}
                </Tag>
                <Tag color="blue" bordered={false}>
                  Basic: {cardData?.[d?.subValue]?.Basic || 0}
                </Tag>
                <Tag color="green" bordered={false}>
                  Good: {cardData?.[d?.subValue]?.Good || 0}
                </Tag>
              </div>
            </div>
          </div>
        ))}
        <div className="col-auto ms-3">
          <Button
            onClick={() => {
              message.destroy();
              message.loading("Loading...", 0);
              GetListAmazonLQSAction({
                page: page,
                perPage: pageSize,
                ...filters,
                ...marketplaceSelected,
                sort: listSortFilters?.key,
                export: "all",
              });
            }}
            size="large"
            type="primary"
            className="ms-3"
          >
            Export
          </Button>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          {isSubmit ? (
            <Table
              scroll={{ x: "max-content" }}
              columns={columns}
              dataSource={list}
              loading={loading}
              pagination={false}
              onChange={handleOnChangeColumn}
            />
          ) : (
            <NoData />
          )}
          <Pagination
            loading={list?.length === 0}
            pageSize={pageSize}
            pageSizeStatus={false}
            page={page}
            totalPage={totalPage}
            onPerPage={onPerPage}
            onPageNo={onPageNo}
          />
        </div>
      </div>

      {isModalVisible && (
        <Modal
          title={scroes?.title}
          visible={isModalVisible}
          width={600}
          onCancel={handleCancel}
          footer={[]}
        >
          <Table
            columns={[
              {
                title: "Metric",
                dataIndex: "metric",
                key: "metric",
              },
              {
                title: "Score",
                dataIndex: "value",
                key: "value",
              },
            ]}
            scroll={{ y: 500 }}
            dataSource={Object?.entries(
              findTableData?.[scroes?.title] || {}
            )?.map(([key, value]) => {
              return {
                metric: key,
                value: swapCommasAndPeriods(scroes?.data?.[value] || 0),
              };
            })}
            pagination={false}
          />
        </Modal>
      )}

      {contextHolder}
    </Wrapper>
  );
};

export default AmazonLqs;
