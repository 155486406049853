import styled from "styled-components";

export const Wrapper = styled.div`
  .eyeIcon {
    fill: #c3645a !important;
    margin-left: 10px;
    cursor: pointer;
  }
  .slick-list {
    border-radius: 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  }
  .ant-btn-primary {
    font-weight: 900 !important;
  }
  .ant-btn-primary:hover {
    background-color: rgb(239 234 239) !important;
    color: #843650 !important;
  }

  .card-bg-add {
    background: #ebedf7 !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  }
  .card-bg-sub-add {
    background: #e8ecf6 !important;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px !important;
  }
  .product_description {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    // max-height: 29px;
    margin: 0 auto;
    font-size: 14px;
    // line-height: 1;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .ant-table-sticky-holder {
    top: 90px !important;
  }
  .ant-badge-status-dot {
    width: 10px !important;
    height: 10px !important;
  }
  .make-box {
    background: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    .label {
      border-right: 1px solid #c5655a54;
      // display: grid;
      // align-items: center;
      // align-content: center;
    }
  }
  .ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
    background: #fafafa !important;
  }
  .ant-table-wrapper td.ant-table-column-sort {
    background: #ffffff;
  }
`;
