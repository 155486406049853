import axiosCall from "../../configurations/network-services/axiosCall";

export const AnalyticsByCardAction = (data) => {
  const path = `analytic-by-card?daterange=${data?.date || ""}&region=${
    data?.region || ""
  }&sales_channel=${data?.sales_channel || ""}&category=${
    data?.category || ""
  }&sub_category=${data?.sub_category || ""}&account_type=${
    data?.account_type || "all"
  }&daterange_type=${data?.daterange_type || ""}`;
  const responseType = "ANALYTIC_BY_CARD";

  return axiosCall("get", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AnalyticsByTableAction = (data) => {
  const path = `analytic-by-table?daterange=${data?.date || ""}&region=${
    data?.region || ""
  }&sales_channel=${data?.sales_channel || ""}&category=${
    data?.category || ""
  }&sub_category=${data?.sub_category || ""}&pageSize=${
    data?.pageSize || 10
  }&page=${data?.page}&account_type=${data?.account_type || "all"}&sort=${
    data?.sort || ""
  }&daterange_type=${data?.daterange_type || ""}`;
  const responseType = "ANALYTIC_BY_TABLE";

  return axiosCall("get", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AnalyticsByLineAction = (data) => {
  const path = `analytic-by-line-graph?daterange=${data?.date || ""}&region=${
    data?.region || ""
  }&sales_channel=${data?.sales_channel || ""}&category=${
    data?.category || ""
  }&sub_category=${data?.sub_category || ""}&account_type=${
    data?.account_type || "all"
  }&daterange_type=${data?.daterange_type || ""}`;
  const responseType = "ANALYTIC_BY_LINE_GRAPH";

  return axiosCall("get", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const AnalyticsByPieAction = (data) => {
  const path = `analytic-by-pie-graph?daterange=${data?.date || ""}&region=${
    data?.region || ""
  }&sales_channel=${data?.sales_channel || ""}&category_filter=${
    data?.category_filter || ""
  }&advertising_filter=${data?.advertising_filter || ""}&category=${
    data?.category || ""
  }&sub_category=${data?.sub_category || ""}&account_type=${
    data?.account_type || "all"
  }&daterange_type=${data?.daterange_type || ""}`;
  const responseType = "ANALYTIC_BY_PIE_GRAPH";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CardOverViewAction = (data) => {
  const path = `card-overview?daterange=${data?.date || ""}&region=${
    data?.region || ""
  }&sales_channel=${data?.sales_channel || ""}`;
  const responseType = "CARD_OVERVIEW_DATA";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const MonthCompareDataAction = (data) => {
  const path = `month-compare?year=${data?.year || ""}&previous_year=${
    data?.previous_year || ""
  }&region=${data?.region || ""}&sales_channel=${
    data?.sales_channel || ""
  }&account_type=${data?.account_type || "all"}`;
  const responseType = "MONTH_COMPARE_DATA";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const MarketplaceDataAction = (data) => {
  const path = `marketplace-data?daterange=${data?.date || ""}&region=${
    data?.region || ""
  }&sales_channel=${data?.sales_channel || ""}&account_type=${
    data?.account_type || "all"
  }&daterange_type=${data?.daterange_type || ""}`;
  const responseType = "MARKETPLACE_DATA";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const ParentDataAction = (data) => {
  const path = `parent-data?daterange=${data?.daterange || ""}&region=${
    data?.region || ""
  }&sales_channel=${data?.sales_channel || ""}&pasgeSize=${
    data?.pasgeSize || 10
  }&page=${data?.page || 1}&daterange_type=${data?.daterange_type || ""}`;
  const responseType = "PARENT_DATA";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const TopProductAction = (data) => {
  const path = `top-product?daterange=${data?.daterange || ""}&region=${
    data?.region || ""
  }&sales_channel=${data?.sales_channel || ""}&daterange_type=${
    data?.daterange_type || ""
  }`;
  const responseType = "TOP_PRODUCTS";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetCategoryAction = (data) => {
  const path = `get-category?region=${data?.region || ""}&sales_channel=${
    data?.sales_channel || ""
  }&account_type=${data?.account_type || "all"}`;
  const responseType = "GET_CATEGORY_LIST";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetSubCategoryAction = (data) => {
  const path = `get-sub-category?region=${data?.region || ""}&sales_channel=${
    data?.sales_channel || ""
  }&account_type=${data?.account_type || "all"}&category=${
    data?.category || ""
  }`;
  const responseType = "GET_SUB_CATEGORY_LIST";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetGraphDataAction = (data) => {
  const path = `country-map?daterange=${data?.date || ""}&region=${
    data?.region || ""
  }&sales_channel=${data?.sales_channel || ""}`;
  const responseType = "GET_GRAPH_DATA";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetVendorAnalyticByCardAction = (data) => {
  const path = `vendor/analytic-by-card?daterange=${data?.start_date || ""}+-+${
    data?.end_date || ""
  }&region=${data?.region || ""}&sales_channel=${
    data?.sales_channel || ""
  }&category=${data?.category || ""}&sub_category=${
    data?.sub_category || ""
  }&daterange_type=${data?.daterange_type || ""}`;
  const responseType = "GET_VENDOR_ANALYTICS_BY_CARD";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetVendorAnalyticByChartAction = (data) => {
  const path = `vendor/analytic-by-chart?region=${
    data?.region || ""
  }&sales_channel=${data?.sales_channel || ""}&daterange=${
    data?.start_date || ""
  }+-+${data?.end_date || ""}&category=${data?.category || ""}&sub_category=${
    data?.sub_category || ""
  }&daterange_type=${data?.daterange_type || ""}`;
  const responseType = "GET_VENDOR_ANALYTICS_BY_CHART";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetVendorOrderStatusAction = (data) => {
  const path = `vendor/order-status`;
  const responseType = "GET_VENDOR_ORDER_STATUS";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetVendorOrderListAction = (data) => {
  const path = `vendor/order-list?sales_channel=${
    data?.sales_channel || ""
  }&daterange=${data?.start_date || ""}+-+${data?.end_date || ""}&region=${
    data?.region || ""
  }&order_status=${data?.order_status || ""}&pageSize=${
    data?.pageSize || 10
  }&page=${data?.page || 1}&daterange_type=${data?.daterange_type || ""}`;
  const responseType = "GET_VENDOR_ORDER_LIST";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionAnalytics = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_ANALYTICS", state: data });
};
