import {
  Badge,
  Button,
  Card,
  Col,
  Progress,
  Row,
  Table,
  Tag,
  Tooltip,
} from "antd";
import React, { Children, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Wrapper } from "./style";
import { Icon } from "@iconify/react";

const OverView = ({
  fakeActionCatalog,
  SalesChannelListAction,
  GetCatalogOverviewAction,
  getCategoryCatalogOverviewAction,
}) => {
  const SalesChannelListResponse = useSelector(
    (state) => state.Catalog.SalesChannelListResponse || {}
  );
  const GetCatelogOverviewResponse = useSelector(
    (state) => state.Catalog.GetCatelogOverviewResponse || {}
  );
  const GetCategoryCatalogOverviewResponse = useSelector(
    (state) => state.Catalog.GetCategoryCatalogOverviewResponse || {}
  );
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [expandData, setExpandData] = useState([]);
  const [expandLoading, setExpandLoading] = useState(true);

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
  });

  useEffect(() => {
    if (GetCategoryCatalogOverviewResponse?.status === true) {
      setExpandData(GetCategoryCatalogOverviewResponse?.data?.data || []);
      setExpandLoading(false);
      fakeActionCatalog("GetCategoryCatalogOverviewResponse");
    } else if (GetCategoryCatalogOverviewResponse?.status === false) {
      setExpandLoading(false);
      setExpandData([]);
      fakeActionCatalog("GetCategoryCatalogOverviewResponse");
    }
  }, [GetCategoryCatalogOverviewResponse]);
  useEffect(() => {
    if (GetCatelogOverviewResponse?.status === true) {
      setList(GetCatelogOverviewResponse?.data?.data || []);
      setLoading(false);
      fakeActionCatalog("GetCatelogOverviewResponse");
    } else if (GetCatelogOverviewResponse?.status === false) {
      setLoading(false);
      setList([]);
      fakeActionCatalog("GetCatelogOverviewResponse");
    }
  }, [GetCatelogOverviewResponse]);

  useEffect(() => {
    GetCatalogOverviewAction();
    setExpandData([]);
    setExpandLoading(true);
    getCategoryCatalogOverviewAction();
    return () => {};
  }, []);

  const SalesChannelCard = (props) => {
    const {
      country,
      active_products,
      productsLive,
      total_products,
      catalog_health,
      items_offer_per_active_product,
      marketplace,
      marketplace_id,
    } = props;

    const statusColor = parseInt(active_products) > 0 ? "success" : "error";

    return (
      <div className="col-xxl-4 col-lg-6 col-md-12">
        <Card
          title={country}
          bordered
          style={{
            borderColor: active_products ? "#78b272" : "#e27b7b",
            borderWidth: "2px",
            borderStyle: "dashed",
          }}
        >
          <div className="d-flex align-item-center justify-content-between">
            <div className=" d-flex align-items-center">
              <img
                src={`/media/domainImage/${marketplace_id}.png`}
                style={{ width: 25 }}
                onError={(e) => {
                  e.target.src =
                    "https://cdn.countryflags.com/thumbs/belgium/flag-round-500.png";
                }}
              />
              <span className="mx-5 fs-4">{marketplace}</span>
              <Tag color="blue">{marketplace_id}</Tag>
            </div>
            <Badge
              status={statusColor}
              text={`Status: ${active_products ? "Active" : "Inactive"}`}
            />
          </div>
          <div className="separator my-5"></div>
          <div className="gap-3 d-grid">
            <div className=" d-flex align-items-center justify-content-between">
              <span>Number of Products Live:</span>

              <span
                className="  rounded  p-2 px-3"
                style={{ border: "1px dashed #c8675b" }}
              >
                {(active_products || 0)?.toLocaleString()}
              </span>
            </div>

            <div className="  d-flex align-items-center justify-content-between">
              <span>Total Products:</span>
              <span
                className="  rounded  p-2 px-3"
                style={{ border: "1px dashed #c8675b" }}
              >
                {(total_products || 0)?.toLocaleString()}
              </span>
            </div>

            <div className=" d-flex align-items-center justify-content-between ">
              <span>Catalog Health:</span>
              <Progress
                type="dashboard"
                percent={parseInt(catalog_health)}
                size={37}
                status="active"
              />
            </div>

            <div
              className="  d-flex align-items-center justify-content-between"
              style={{ padding: "5.078px 0px" }}
            >
              <span>Amazon Buybox Performance:</span>
              <Progress
                className="w-100px"
                percent={parseInt(items_offer_per_active_product || 0)}
                size="small"
                status="active"
              />
            </div>
          </div>
        </Card>
      </div>
    );
  };

  const SalesChannelsList = ({ channels }) => {
    return (
      <div className="row gy-5 mt-3">
        {channels.map((channel) => (
          <SalesChannelCard key={channel.country} {...channel} />
        ))}
      </div>
    );
  };

  const columns = [
    {
      title: "Sales Channel",

      render: (e) => {
        return (
          <span>
            Amazon.{e.account_type}.{e?.marketplace_short_name}
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "active_products",
      key: "active_products",
      render: (e) => {
        return <Badge size={100} color={parseInt(e) > 0 ? "green" : "red"} />;
      },
    },
    {
      title: (
        <>
          Number or Products Live{" "}
          <Tooltip
            placement="bottom"
            title="Count of Active Products that are listed in Amazon"
          >
            <Icon width={18} height={18} icon="material-symbols:info" />
          </Tooltip>
        </>
      ),

      render: (e) => {
        return <span>{e?.active_products?.toLocaleString()}</span>;
      },
    },
    {
      title: (
        <>
          Total Products{" "}
          <Tooltip
            placement="bottom"
            title="Count of Products added from the Catalog Manual Import Products that includes Child and Single ASINs"
          >
            <Icon width={18} height={18} icon="material-symbols:info" />
          </Tooltip>
        </>
      ),

      render: (e) => {
        return <span>{e?.total_products?.toLocaleString()}</span>;
      },
    },
    {
      title: (
        <>
          Catalog Health{" "}
          <Tooltip
            placement="bottom"
            title="Formulated as Product that are matched with Amazon / Total Product uploaded from Amazon * 100"
          >
            <Icon width={18} height={18} icon="material-symbols:info" />
          </Tooltip>
        </>
      ),
      dataIndex: "catalog_health",
      key: "catalog_health",
      align: "center",
      render: (e) => {
        return <Progress size={50} type="dashboard" percent={parseInt(e)} />;
      },
    },
    {
      title: (
        <>
          Amazon Buybox Performance{" "}
          <Tooltip
            placement="bottom"
            title="Formulated as Buy Box Winner Count / Amazon Catalog Active Product Count * 100"
          >
            <Icon width={18} height={18} icon="material-symbols:info" />
          </Tooltip>
        </>
      ),
      dataIndex: "items_offer_per_active_product",
      key: "items_offer_per_active_product",
      align: "center",
      width: 350,
      render: (e) => {
        return <Progress type="line" percent={parseInt(e || 0)} />;
      },
    },
  ];
  const columns_expand = [
    {
      title: "Catagory",

      render: (e) => {
        return <span>{e?.category}</span>;
      },
    },
    // {
    //   title: "Status",
    //   dataIndex: "active_products",
    //   key: "active_products",
    //   render: (e) => {
    //     return <Badge size={100} color={parseInt(e) > 0 ? "green" : "red"} />;
    //   },
    // },
    {
      title: (
        <>
          Number or Products Live{" "}
          <Tooltip
            placement="bottom"
            title="Count of Active Products that are listed in Amazon"
          >
            <Icon width={18} height={18} icon="material-symbols:info" />
          </Tooltip>
        </>
      ),

      render: (e) => {
        return <span>{e?.active_products?.toLocaleString()}</span>;
      },
    },
    {
      title: (
        <>
          Total Products{" "}
          <Tooltip
            placement="bottom"
            title="Count of Products added from the Catalog Manual Import Products that includes Child and Single ASINs"
          >
            <Icon width={18} height={18} icon="material-symbols:info" />
          </Tooltip>
        </>
      ),

      render: (e) => {
        return <span>{e?.total_products?.toLocaleString()}</span>;
      },
    },
    {
      title: (
        <>
          Catalog Health{" "}
          <Tooltip
            placement="bottom"
            title="Formulated as Product that are matched with Amazon / Total Product uploaded from Amazon * 100"
          >
            <Icon width={18} height={18} icon="material-symbols:info" />
          </Tooltip>
        </>
      ),
      dataIndex: "catalog_health",
      key: "catalog_health",
      align: "center",
      render: (e) => {
        return <Progress size={50} type="dashboard" percent={parseInt(e)} />;
      },
    },
    {
      title: (
        <>
          Amazon Buybox Performance{" "}
          <Tooltip
            placement="bottom"
            title="Formulated as Buy Box Winner Count / Amazon Catalog Active Product Count * 100"
          >
            <Icon width={18} height={18} icon="material-symbols:info" />
          </Tooltip>
        </>
      ),
      dataIndex: "items_offer_per_active_product",
      key: "items_offer_per_active_product",
      align: "center",
      width: 350,
      render: (e) => {
        return <Progress type="line" percent={parseInt(e || 0)} />;
      },
    },
  ];
  const columns_expand_sub = [
    {
      title: "Sub Catagory",

      render: (e) => {
        return <span>{e?.sub_category}</span>;
      },
    },
    // {
    //   title: "Status",
    //   dataIndex: "active_products",
    //   key: "active_products",
    //   render: (e) => {
    //     return <Badge size={100} color={parseInt(e) > 0 ? "green" : "red"} />;
    //   },
    // },
    {
      title: (
        <>
          Number or Products Live{" "}
          <Tooltip
            placement="bottom"
            title="Count of Active Products that are listed in Amazon"
          >
            <Icon width={18} height={18} icon="material-symbols:info" />
          </Tooltip>
        </>
      ),

      render: (e) => {
        return <span>{e?.active_products?.toLocaleString()}</span>;
      },
    },
    {
      title: (
        <>
          Total Products{" "}
          <Tooltip
            placement="bottom"
            title="Count of Products added from the Catalog Manual Import Products that includes Child and Single ASINs"
          >
            <Icon width={18} height={18} icon="material-symbols:info" />
          </Tooltip>
        </>
      ),

      render: (e) => {
        return <span>{e?.total_products?.toLocaleString()}</span>;
      },
    },
    {
      title: (
        <>
          Catalog Health{" "}
          <Tooltip
            placement="bottom"
            title="Formulated as Product that are matched with Amazon / Total Product uploaded from Amazon * 100"
          >
            <Icon width={18} height={18} icon="material-symbols:info" />
          </Tooltip>
        </>
      ),
      dataIndex: "catalog_health",
      key: "catalog_health",
      align: "center",
      render: (e) => {
        return <Progress size={50} type="dashboard" percent={parseInt(e)} />;
      },
    },
    {
      title: (
        <>
          Amazon Buybox Performance{" "}
          <Tooltip
            placement="bottom"
            title="Formulated as Buy Box Winner Count / Amazon Catalog Active Product Count * 100"
          >
            <Icon width={18} height={18} icon="material-symbols:info" />
          </Tooltip>
        </>
      ),
      dataIndex: "items_offer_per_active_product",
      key: "items_offer_per_active_product",
      align: "center",
      width: 350,
      render: (e) => {
        return <Progress type="line" percent={parseInt(e || 0)} />;
      },
    },
  ];
  if (1 !== 1) {
    return <SalesChannelsList channels={list} />;
  }
  console.log(expandData, "expandData");

  return (
    <Wrapper className="p-5 fadeInBottom">
      <div className="card">
        <div className="card-body">
          <Table
            loading={loading}
            dataSource={list?.map((d, i) => {
              return {
                ...d,
                key: i,
              };
            })}
            rowKey={"key"}
            pagination={false}
            columns={columns}
            scroll={{ x: "max-content" }}
            expandable={{
              expandedRowRender: (e) => {
                const expandDataList = expandData?.filter(
                  (d) =>
                    d?.account_type === e?.account_type &&
                    d?.marketplace_id === e?.marketplace_id
                );

                return (
                  <div className="card card-bg-add mx-6">
                    <div className="card-body  ">
                      <Table
                        dataSource={expandDataList
                          ?.map((d) => d?.category)
                          ?.flat()
                          ?.map((d, i) => ({
                            ...d,
                            index: i + 1,
                          }))}
                        columns={columns_expand}
                        size="small"
                        pagination={false}
                        scroll={{ x: "max-content" }}
                        rowKey={"index"}
                        expandable={{
                          expandedRowRender: (r) => {
                            console.log(r?.sub_category, "rddasdasdasd");

                            // const expandDataList = expandData?.filter(
                            //   (d) =>
                            //     d?.account_type === e?.account_type &&
                            //     d?.marketplace_id === e?.marketplace_id
                            // );

                            return (
                              <div className="card mx-6 card-bg-sub-add">
                                <div className="card-body">
                                  <Table
                                    dataSource={r?.sub_category}
                                    expandable={false}
                                    columns={columns_expand_sub}
                                    size="small"
                                    pagination={false}
                                    scroll={{ x: "max-content" }}
                                  />
                                </div>
                              </div>
                            );
                          },
                          expandIcon: ({ expanded, onExpand, record }) => (
                            <Button
                              className="cursor-pointer"
                              type="link"
                              onClick={(e) => {
                                onExpand(record);
                                e.stopPropagation();
                              }}
                            >
                              {expanded ? (
                                <Icon icon="ic:baseline-minus" width={20} />
                              ) : (
                                <Icon icon="ic:outline-plus" width={20} />
                              )}
                            </Button>
                          ),
                        }}
                      />
                    </div>
                  </div>
                );
              },
              expandIcon: ({ expanded, onExpand, record }) => (
                <Button
                  className="cursor-pointer"
                  type="link"
                  onClick={(e) => {
                    onExpand(record);
                    e.stopPropagation();
                  }}
                >
                  {/* {console.log(
                    expandData?.filter(
                      (d) =>
                        d?.account_type === record?.account_type &&
                        d?.marketplace_id === record?.marketplace_id
                    ),
                    record,
                    "expandData"
                  )} */}
                  {expanded ? (
                    <Icon icon="ic:baseline-minus" width={20} />
                  ) : (
                    <Icon icon="ic:outline-plus" width={20} />
                  )}
                </Button>
              ),
            }}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default OverView;
