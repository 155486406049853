import React from "react";
import ReactApexChart from "react-apexcharts";
import { SwapCommaAndDot } from "../../../config";

const PieChart = ({ marketplaceSelected, data }) => {
  // Extract keys and values from the data

  const chartOptions = {
    chart: {
      type: "pie",
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
      },
      // events: {
      //   dataPointMouseEnter: function (event, chartContext, config) {
      //     chartContext.updateOptions({
      //       plotOptions: {
      //         pie: {
      //           expandOnClick: true,
      //         },
      //       },
      //     });
      //   },
      //   dataPointMouseLeave: function (event, chartContext, config) {
      //     chartContext.updateOptions({
      //       plotOptions: {
      //         pie: {
      //           expandOnClick: false,
      //         },
      //       },
      //     });
      //   },
      // },
    },
    labels: Object?.keys(data),
    legend: {
      show: true,
      position: "right",
      horizontalAlign: "center",
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          legend: {
            position: "bottom",
          },
        },
      },
    ],
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return (
          SwapCommaAndDot(
            parseFloat(val.toFixed(2) || 0)?.toLocaleString(),
            "",
            "",
            marketplaceSelected?.marketplace_id
          ) + "%"
        );
      },
    },
    colors: ["#ff6f61", "#ffb347", "#4ac29a", "#74c0fc"], // Custom colors
    tooltip: {
      enabled: true,
      y: {
        formatter: function (value) {
          return SwapCommaAndDot(
            parseFloat(value || 0)?.toLocaleString(),
            "",
            "",
            marketplaceSelected?.marketplace_id
          );
        },
      },
    },
  };

  const chartSeries = Object?.values(data)?.map((d) => parseFloat(d));

  console.log(data, "datadatadata");

  return (
    <div>
      <ReactApexChart
        options={chartOptions}
        series={chartSeries}
        type="pie"
        height="350"
      />
    </div>
  );
};

export default PieChart;
