import { ArrowUpOutlined } from "@ant-design/icons";
import {
  Card,
  DatePicker,
  Empty,
  Result,
  Select,
  Skeleton,
  Spin,
  Statistic,
  Table,
  Tag,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import dayjs from "dayjs";
import moment from "moment";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { GlobalContext } from "../../../commonContext";
import Pagination from "../../../components/pagination";
import {
  AllMarketPlace,
  DefaultPerPage,
  SwapCommaAndDot,
  checkPermission,
  getDateRangeType,
  updateDate,
} from "../../../config";
import { Wrapper } from "./new-style";
import PieChart from "./pie-chart";

const today = dayjs();
const yesterday = dayjs().subtract(1, "day");

const Analytics = (props) => {
  const {
    fakeActionCatalog,
    SalesChannelListAction,
    AnalyticsByCardAction,

    fakeActionAnalytics,
    AnalyticsByLineAction,
    GetCategoryAction,
    GetSubCategoryAction,
    AnalyticsByTableAction,

    AnalyticsByPieAction,
  } = props;

  const contextValue = useContext(GlobalContext);
  const [cardLoading, setCardLoading] = useState(true);
  const [cardData, setCardData] = useState({});

  const [sign, setSign] = useState("$");

  const [tableLoading, setTableLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const [lineLoading, setLineLoading] = useState(true);
  const [lineData, setLineData] = useState([]);

  const [dateFilter, setDateFilter] = useState({
    start: dayjs().subtract(30, "days")?.format("YYYY-MM-DD"),
    end: dayjs()?.format("YYYY-MM-DD"),
    daterange_type: "",
  });

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
    account_type: null,
  });

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [filters, setFilters] = useState({
    category_filter: "adv_impressions",
    advertising_filter: "adv_impressions",
  });

  const [lineFilter, setLineFilter] = useState([]);
  const [selectedLineChartData, setSelectedLineChartData] = useState({});

  const [piechartData, setPiechartData] = useState({
    advertisingData: [],
    categoryAdvertisingData: [],
  });

  const [selectedCategorys, setSelectedCategorys] = useState({
    category: null,
    subCategory: null,
  });
  const [categoryList, setCategoryList] = useState([]);
  const [categoryLoading, setCategoryLoading] = useState(true);

  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryLoading, setSubCategoryLoading] = useState(false);

  const [selectedChartData, setSelectedChartData] = useState({
    Views: [1200, 1400, 1100, 1800, 2500, 2200, 2900],
  });
  const [listSortFilters, setListSortFilters] = useState({
    key: "",
    type: "",
  });

  const SalesChannelListResponse = useSelector(
    (state) => state.Catalog.SalesChannelListResponse || {}
  );
  const AnalyticByCardResponse = useSelector(
    (state) => state.Analytics.AnalyticByCardResponse || {}
  );
  const AnalyticByTableResponse = useSelector(
    (state) => state.Analytics.AnalyticByTableResponse || {}
  );
  const AnalyticByLineGraphResponse = useSelector(
    (state) => state.Analytics.AnalyticByLineGraphResponse || {}
  );
  const AnalyticByPieGraphResponse = useSelector(
    (state) => state.Analytics.AnalyticByPieGraphResponse || {}
  );
  const GetCategoryListResponse = useSelector(
    (state) => state.Analytics.GetCategoryListResponse || {}
  );
  const GetSubCategoryListResponse = useSelector(
    (state) => state.Analytics.GetSubCategoryListResponse || {}
  );

  useEffect(() => {
    if (AnalyticByPieGraphResponse?.status === true) {
      setPiechartData({
        advertisingData: AnalyticByPieGraphResponse?.data?.advertisingData,
        categoryAdvertisingData:
          AnalyticByPieGraphResponse?.data?.categoryAdvertisingData,
      });
      fakeActionAnalytics("AnalyticByPieGraphResponse");
    } else if (AnalyticByPieGraphResponse?.status === false) {
      message.destroy();
      message.warning(AnalyticByTableResponse?.message);
      fakeActionAnalytics("AnalyticByPieGraphResponse");
    }
  }, [AnalyticByPieGraphResponse]);

  useEffect(() => {
    if (AnalyticByTableResponse?.status === true) {
      setSign(AnalyticByTableResponse?.data?.currency);
      setTableData(AnalyticByTableResponse?.data?.data || []);
      setTotalPage(AnalyticByTableResponse?.data?.pagination?.totalCount);
      setTableLoading(false);
      fakeActionAnalytics("AnalyticByTableResponse");
    } else if (AnalyticByTableResponse?.status === false) {
      setTableLoading(false);
      setTableData([]);
      message.destroy();
      message.warning(AnalyticByTableResponse?.message);
      fakeActionAnalytics("AnalyticByTableResponse");
    }
  }, [AnalyticByTableResponse]);
  useEffect(() => {
    if (AnalyticByLineGraphResponse?.status === true) {
      setSign(AnalyticByLineGraphResponse?.data?.currency);
      const chartData =
        AnalyticByLineGraphResponse?.data?.data?.chartData || [];
      const valuesByUniqueKey = chartData.reduce((acc, obj) => {
        Object.entries(obj).forEach(([key, value]) => {
          acc[key] = (acc[key] || []).concat(parseInt(value));
        });
        return acc;
      }, {});
      const obj = valuesByUniqueKey;
      delete obj?.label;

      setLineData({
        series: obj,
        label: AnalyticByLineGraphResponse?.data?.data?.chartData?.map(
          (d) => d?.label
        ),
      });
      setSelectedLineChartData(obj);
      setLineFilter(Object?.keys(obj));
      setSelectedChartData(obj);
      setLineLoading(false);
      fakeActionAnalytics("AnalyticByLineGraphResponse");
    } else if (AnalyticByLineGraphResponse?.status === false) {
      setLineLoading(false);
      message.destroy();
      message.warning(AnalyticByLineGraphResponse?.message);
      fakeActionAnalytics("AnalyticByLineGraphResponse");
    }
  }, [AnalyticByLineGraphResponse]);
  useEffect(() => {
    if (GetCategoryListResponse?.status === true) {
      setCategoryLoading(false);
      setCategoryList(
        GetCategoryListResponse?.data?.data?.map((d) => ({
          label: d?.category,
          value: d?.category,
        }))
      );
      fakeActionAnalytics("GetCategoryListResponse");
    } else if (GetCategoryListResponse?.status === false) {
      setCategoryList([]);
      setCategoryLoading(false);
      message.destroy();
      message.warning(GetCategoryListResponse?.message);
      fakeActionAnalytics("GetCategoryListResponse");
    }
  }, [GetCategoryListResponse]);
  useEffect(() => {
    if (GetSubCategoryListResponse?.status === true) {
      setSubCategoryLoading(false);
      setSubCategoryList(
        GetSubCategoryListResponse?.data?.data?.map((d) => ({
          label: d?.sub_category,
          value: d?.sub_category,
        }))
      );
      fakeActionAnalytics("GetSubCategoryListResponse");
    } else if (GetSubCategoryListResponse?.status === false) {
      setSubCategoryList([]);
      setSubCategoryLoading(false);
      message.destroy();
      message.warning(GetSubCategoryListResponse?.message);
      fakeActionAnalytics("GetSubCategoryListResponse");
    }
  }, [GetSubCategoryListResponse]);
  useEffect(() => {
    if (AnalyticByCardResponse?.status === true) {
      setSign(AnalyticByCardResponse?.data?.currency);

      setCardData(AnalyticByCardResponse?.data?.data);
      setCardLoading(false);
      fakeActionAnalytics("AnalyticByCardResponse");
    } else if (AnalyticByCardResponse?.status === false) {
      setCardLoading(false);
      message.destroy();
      message.warning(AnalyticByCardResponse?.message);
      fakeActionAnalytics("AnalyticByCardResponse");
    }
  }, [AnalyticByCardResponse]);

  const getCardGraph = (data) => {
    const obj = {
      sales_channel: data?.sales_channel,
      region: data?.region,
      date: data?.start + "+-+" + data?.end,
      category_filter: data?.category_filter,
      advertising_filter: data?.advertising_filter,
      daterange_type: data?.daterange_type,
    };

    AnalyticsByPieAction(obj);
  };

  const getCard = (data) => {
    setCardLoading(true);
    setCardData([]);

    setTableData([]);
    setTableLoading(true);
    const obj = {
      sales_channel: data?.sales_channel,
      region: data?.region,
      date: data?.start + "+-+" + data?.end,
      category_filter: data?.category_filter,
      advertising_filter: data?.advertising_filter,
      category: data?.category,
      sub_category: data?.sub_category,
      pageSize: data?.pageSize || pageSize,
      page: data?.page || page,
      account_type: data?.account_type,
      daterange_type: data?.daterange_type,
    };

    AnalyticsByCardAction(obj);
    AnalyticsByTableAction(obj);
    AnalyticsByLineAction(obj);
    AnalyticsByPieAction(obj);
  };

  const onPageNo = (e) => {
    setPage(e);
    setTableLoading(true);

    const obj = {
      sales_channel: marketplaceSelected?.sales_channel,
      region: marketplaceSelected?.region,
      date: filters?.start + "+-+" + filters?.end,
      category_filter: filters?.category_filter,
      advertising_filter: filters?.advertising_filter,
      category: filters?.category,
      sub_category: filters?.sub_category,
      pageSize: pageSize,
      page: e,
    };

    AnalyticsByTableAction(obj);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setTableLoading(true);

    const obj = {
      sales_channel: marketplaceSelected?.sales_channel,
      region: marketplaceSelected?.region,
      date: filters?.start + "+-+" + filters?.end,
      category_filter: filters?.category_filter,
      advertising_filter: filters?.advertising_filter,
      category: filters?.category,
      sub_category: filters?.sub_category,
      pageSize: e,
      page: page,
    };

    AnalyticsByTableAction(obj);
  };

  useEffect(() => {
    if (SalesChannelListResponse?.status === true) {
      if (SalesChannelListResponse?.data?.records?.length > 0) {
        const salesList = SalesChannelListResponse?.data?.records?.reduce(
          (acc, e) => {
            const regionIndex = acc.findIndex(
              (r) => r.label === e.region_label
            );

            if (e?.default_marketplace === 1) {
              setMarketplaceSelected({
                marketplace: e?.marketplace,
                marketplace_id: e?.marketplace_id,
                region: e?.region,
                sales_channel: e?.sales_channel,
                account_type: e?.account_type,
              });
              setCategoryLoading(true);
              GetCategoryAction({
                region: e?.region,
                sales_channel: e?.sales_channel,
                account_type: e?.account_type,
              });
              setSubCategoryLoading(true);
              GetSubCategoryAction({
                region: e?.region,
                sales_channel: e?.sales_channel,
                account_type: e?.account_type,
              });
              getCard({
                region: e?.region,
                sales_channel: e?.sales_channel,
                start: dateFilter?.start,
                end: dateFilter?.end,
                advertising_filter: filters?.advertising_filter,
                category_filter: filters?.category_filter,
                daterange_type: dateFilter?.daterange_type,
                account_type: e?.account_type,
              });
            }

            if (regionIndex === -1) {
              acc.push({
                label: e.region_label,
                options: [
                  {
                    label: (
                      <>
                        <img
                          src={`/domainImage/${e?.marketplace_flag}.png`}
                          style={{ height: "20px" }}
                          loading="lazy"
                          className="me-3"
                          onError={(e) => {
                            e.target.src = "/media/domainImage/red-flag.png";
                          }}
                        />
                        {e?.region_label === "Ecommerce"
                          ? "Ecommerce"
                          : `Amazon.${e?.account_type || ""}.${
                              e?.short_name || ""
                            }`}
                      </>
                    ),
                    value: e.marketplace_id,
                    region: e.region,
                    marketplace_id: e?.marketplace_id,
                    marketplace: e?.marketplace,
                    sales_channel: e?.sales_channel,
                    account_type: e?.account_type,
                  },
                ],
              });
            } else {
              acc[regionIndex].options.push({
                label: (
                  <>
                    <img
                      src={`/domainImage/${e?.marketplace_flag}.png`}
                      style={{ height: "20px" }}
                      loading="lazy"
                      className="me-3"
                      onError={(e) => {
                        e.target.src = "/media/domainImage/red-flag.png";
                      }}
                    />
                    {`Amazon.${e?.account_type || ""}.${e?.short_name || ""}`}
                  </>
                ),
                value: e.marketplace_id,
                marketplace_id: e?.marketplace_id,
                region: e.region,
                marketplace: e?.marketplace,
                sales_channel: e?.sales_channel,
                account_type: e?.account_type,
              });
            }
            return acc;
          },
          []
        );

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);

        setMarketplaceList([AllMarketPlace, ...uniqueList]);
      } else {
        setMarketplaceLoading(false);
        setTableLoading(false);
        setLineLoading(false);
        setCardLoading(false);
        setMarketplaceList([]);
      }
      setMarketplaceLoading(false);
      fakeActionCatalog("SalesChannelListResponse");
    } else if (SalesChannelListResponse?.status === false) {
      setMarketplaceLoading(false);
      setTableLoading(false);
      setLineLoading(false);
      setCardLoading(false);
      setMarketplaceList([]);
      message.destroy();
      message.warning(SalesChannelListResponse?.message);
      fakeActionCatalog("SalesChannelListResponse");
    }
  }, [SalesChannelListResponse]);
  const currentMonth = moment().month(); // Get the current month (0-indexed)
  const quarterStart = moment()
    .startOf("year")
    .add(currentMonth - (currentMonth % 3), "months");
  const quarterEnd = quarterStart.clone().add(3, "months").subtract(1, "day");

  const lastQuarterStart = moment()
    .startOf("year")
    .add(currentMonth - (currentMonth % 3) - 3, "months");
  const lastQuarterEnd = lastQuarterStart
    .clone()
    .add(3, "months")
    .subtract(1, "day");
  const rangePresets = [
    {
      label: "Today",
      value: [dayjs(), dayjs()],
      type: "last_30_days_by_day",
      weekType: "date-view",
    },
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
      type: "last_30_days_by_day",
      weekType: "date-view",
    },
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs()],
      type: "last_30_days_by_day",
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
      type: "last_30_days_by_day",
      daterange_type: "last_month",
    },
    {
      label: "This Quarter",
      value: [dayjs(quarterStart), dayjs(quarterEnd)],
      type: "last_12_months_by_month",
    },
    {
      label: "Last Quarter",
      value: [dayjs(lastQuarterStart), dayjs(lastQuarterEnd)],
      type: "last_12_months_by_month",
      daterange_type: "last_quarter",
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
      type: "last_12_months_by_month",
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
      type: "last_12_months_by_month",
      daterange_type: "last_year",
    },
    {
      label: "Custom Range",
      value: [0, 0],
      type: "last_12_months_by_month",
    },
  ];

  function capitalizeFirstLetter(str) {
    // Split the string into words
    let words = str.split(" ");

    // Capitalize the first letter of each word
    let capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the words back into a string
    let capitalizedString = capitalizedWords.join(" ");

    return capitalizedString;
  }
  const chartOptions = {
    chart: {
      height: 280,
      type: "area",
      toolbar: { show: !1 },
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: !0,
        top: 12,
        left: 0,
        bottom: 0,
        right: 0,
        blur: 2,
        color: "rgba(132, 145, 183, 0.4)",
        opacity: 0.45,
      },
    },
    colors: [
      "#FF5733",
      "#33FF57",
      "#5733FF",
      "#FF33A8",
      "#33A8FF",
      "#A833FF",
      "#FF3380",
    ],
    dataLabels: {
      enabled: true,
      formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
        // return val.toLocaleString();
        const withTitle_ = w?.config?.series?.[seriesIndex];

        return withTitle_?.name === "Adv Click-Thru Rate" ||
          withTitle_?.name === "Adv Revenue" ||
          withTitle_?.name === "Adv Spend" ||
          withTitle_?.name === "Order Revenue" ||
          withTitle_?.name === "Overall Revenue"
          ? SwapCommaAndDot(
              parseFloat(value).toLocaleString(),
              sign,
              "",
              marketplaceSelected?.marketplace_id
            )
          : withTitle_?.name === "Sales Percent" ||
            withTitle_?.name === "Ad Click Through Rate"
          ? SwapCommaAndDot(
              parseFloat(value).toLocaleString(),
              "",
              "",
              marketplaceSelected?.marketplace_id
            ) + "%"
          : SwapCommaAndDot(
              parseFloat(value).toLocaleString(),
              "",
              "",
              marketplaceSelected?.marketplace_id
            );
      },
    },
    stroke: {
      show: !0,
      curve: "smooth",
      width: [3, 3],
      dashArray: [0, 4],
      lineCap: "round",
    },

    labels: lineData?.label,
    yaxis: {
      labels: {
        formatter: function (_) {
          return parseFloat(_)?.toLocaleString() + "";
        },
        offsetX: -12,
        offsetY: 0,
      },
    },
    grid: {
      strokeDashArray: 3,
      xaxis: { lines: { show: !0 } },
      yaxis: { lines: { show: !1 } },
    },
    legend: { show: !0 },
    tooltip: {
      marker: {
        show: true,
      },
      // x: {
      //   show: false,
      // },
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          const withTitle_ = w?.config?.series?.[seriesIndex];

          return withTitle_?.name === "Adv Click-Thru Rate" ||
            withTitle_?.name === "Adv Revenue" ||
            withTitle_?.name === "Adv Spend" ||
            withTitle_?.name === "Order Revenue" ||
            withTitle_?.name === "Overall Revenue"
            ? SwapCommaAndDot(
                parseFloat(value).toLocaleString(),
                sign,
                "",
                marketplaceSelected?.marketplace_id
              )
            : withTitle_?.name === "Sales Percent" ||
              withTitle_?.name === "Ad Click Through Rate"
            ? SwapCommaAndDot(
                parseFloat(value).toLocaleString(),
                "",
                "",
                marketplaceSelected?.marketplace_id
              ) + "%"
            : SwapCommaAndDot(
                parseFloat(value).toLocaleString(),
                "",
                "",
                marketplaceSelected?.marketplace_id
              );
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 1,
        inverseColors: !1,
        opacityFrom: 0.05,
        opacityTo: 0.05,
        stops: [45, 100],
      },
    },
  };

  useEffect(() => {
    setMarketplaceList([]);
    setMarketplaceLoading(true);
    SalesChannelListAction();

    setDateFilter({
      start: contextValue?.data?.date_range?.start_date,
      end: contextValue?.data?.date_range?.end_date,
      daterange_type: contextValue?.data?.date_range?.daterange_type,
    });

    return () => {};
  }, []);

  const listExtraProps = (type) => {
    return {
      defaultSortOrder:
        type === listSortFilters?.key?.replace("-", "")
          ? listSortFilters.type
          : [],
      sorter: () => {},
    };
  };

  const handleOnChangeColumn = (_, __, sorter) => {
    const sort =
      sorter?.order === "ascend"
        ? sorter?.column?.key
        : sorter?.order === "descend"
        ? `-${sorter?.column?.key}`
        : "";

    setTableData([]);
    setTableLoading(true);
    const obj = {
      sales_channel: marketplaceSelected?.sales_channel,
      region: marketplaceSelected?.region,
      date: dateFilter?.start + "+-+" + dateFilter?.end,
      category_filter: filters?.category_filter,
      advertising_filter: filters?.advertising_filter,
      category: filters?.category,
      sub_category: filters?.sub_category,
      pageSize: pageSize,
      page: page,
      account_type: filters?.account_type,
      sort,
    };

    setListSortFilters({
      key: sort,
      type: sorter?.order || "",
    });
    AnalyticsByTableAction(obj);
  };

  const sineView = {
    add_to_cart: {
      s: "",
      p: "",
      d: false,
    },
    "adv_click-Thru_rate": {
      s: "",
      p: "",
      d: true,
    },
    adv_clicks: {
      s: "",
      p: "",
      d: false,
    },
    adv_impressions: {
      s: "",
      p: "",
      d: false,
    },
    adv_orders: {
      s: "",
      p: "",
      d: false,
    },
    adv_revenue: {
      s: "",
      p: sign,
      d: false,
    },
    adv_spend: {
      s: "",
      p: sign,
      d: false,
    },
    order_revenue: {
      s: "",
      p: sign,
      d: false,
    },
    orders: {
      s: "",
      p: "",
      d: false,
    },
    overall_revenue: {
      s: "",
      p: sign,
      d: true,
    },
    units_ordered: {
      s: "",
      p: "",
      d: true,
    },
    views: {
      s: "",
      p: "",
      d: false,
    },
    ACOS: {
      s: "",
      p: "%",
      d: true,
    },
    TACOS: {
      s: "",
      p: "%",
      d: true,
    },
    ROAS: {
      s: "",
      p: "",
      d: true,
    },
    ROI: {
      s: "",
      p: "%",
      d: true,
    },
  };

  const sineAdd = {
    adv_revenue: {
      f: sign,
      l: null,
    },
    overall_revenue: {
      f: sign,
      l: null,
    },
    adv_spend: {
      f: sign,
      l: null,
    },
    adv_impressions: {
      f: null,
      l: null,
    },
    adv_clicks: {
      f: null,
      l: null,
    },
    adv_click_through_rate: {
      f: null,
      l: "%",
    },
    order_revenue: {
      f: sign,
      l: null,
    },
    orders: {
      f: null,
      l: null,
    },
    order_revenue_percentage: {
      f: null,
      l: "%",
    },
  };

  const colorUpdate = (value) => {
    if (parseFloat(value) >= 0) {
      return <span style={{ color: "green" }}>{value}%</span>;
    }
    return <span style={{ color: "red" }}>{value}%</span>;
  };

  const findSign = {
    adv_revenue: sign,
    adv_spend: sign,
    adv_clicks: "",
    adv_impressions: "",
    adv_orders: "",
    "adv_click-Thru_rate": "%",
    overall_revenue: sign,
    orders: "",
    units_ordered: "",
    ACOS: "%",
    TACOS: "%",
    ROAS: "",
    ROI: "",
    views: "",
    add_to_cart: "",
  };

  if (checkPermission(contextValue, "analytics", "view")) {
    return (
      <Result
        status="403"
        title="403"
        message="Sorry, you are not authorized to access this page."
      />
    );
  }

  // if (contextValue?.data?.account_type === 2) {
  //   return <VendorPerformance />;
  // }

  return (
    <Wrapper className="p-4 fadeInBottom">
      <div className="d-flex justify-content-end">
        <div className="fix-over">
          <Select
            placeholder="Category"
            style={{ width: "200px" }}
            size="large"
            allowClear
            filterOption={(input, option) =>
              option?.label.toLowerCase().includes(input.toLowerCase())
            }
            showSearch
            options={categoryList}
            loading={categoryLoading}
            value={selectedCategorys?.category || null}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(e) => {
              setSelectedCategorys({
                ...selectedCategorys,
                subCategory: null,
                category: e,
              });
              setSubCategoryLoading(true);
              GetSubCategoryAction({
                region: marketplaceSelected?.region,
                sales_channel: marketplaceSelected?.sales_channel,
                category: e,
              });
              getCard({
                region: marketplaceSelected?.region,
                sales_channel: marketplaceSelected?.sales_channel,
                start: dateFilter?.start,
                end: dateFilter?.end,
                advertising_filter: filters?.advertising_filter,
                category_filter: filters?.category_filter,
                ...selectedCategorys,
                category: e,
                daterange_type: dateFilter?.daterange_type,
                sub_category: null,
                account_type: marketplaceSelected?.account_type,
              });
            }}
          />
        </div>
        <div className="fix-over">
          <Select
            placeholder="Sub Category"
            style={{ width: "200px" }}
            className="ms-3"
            filterOption={(input, option) =>
              option?.label.toLowerCase().includes(input.toLowerCase())
            }
            showSearch
            onDropdownVisibleChange={(e) => {
              if (e && !selectedCategorys?.category) {
                message.destroy();
                message.warning('Please select "Category" first');
              }
            }}
            allowClear
            size="large"
            options={subCategoryList}
            loading={subCategoryLoading}
            value={selectedCategorys?.subCategory || null}
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(e) => {
              setSelectedCategorys({
                ...selectedCategorys,
                subCategory: e,
              });
              getCard({
                region: marketplaceSelected?.region,
                sales_channel: marketplaceSelected?.sales_channel,
                start: dateFilter?.start,
                daterange_type: dateFilter?.daterange_type,
                end: dateFilter?.end,
                advertising_filter: filters?.advertising_filter,
                category_filter: filters?.category_filter,
                ...selectedCategorys,
                sub_category: e,
                account_type: marketplaceSelected?.account_type,
              });
            }}
          />
        </div>
        <div className="fix-over">
          <DatePicker.RangePicker
            presets={rangePresets}
            className="ms-3"
            size="large"
            value={
              dateFilter?.start && dateFilter?.end
                ? [
                    dayjs(dateFilter?.start, "YYYY-MM-DD"),
                    dayjs(dateFilter?.end, "YYYY-MM-DD"),
                  ]
                : null
            }
            onChange={(e) => {
              getCard({
                region: marketplaceSelected?.region,
                sales_channel: marketplaceSelected?.sales_channel,
                start: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
                end: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
                advertising_filter: filters?.advertising_filter,
                category_filter: filters?.category_filter,
                account_type: marketplaceSelected?.account_type,
                daterange_type: getDateRangeType(e, rangePresets)
                  ?.daterange_type,
              });
              setDateFilter({
                start: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
                end: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
                daterange_type: getDateRangeType(e, rangePresets)
                  ?.daterange_type,
              });

              const date_range = {
                start_date: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
                end_date: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
                daterange_type: getDateRangeType(e, rangePresets)
                  ?.daterange_type,
              };
              contextValue?.updateCommonGlobalVal({
                date_range,
              });
              updateDate(date_range);
            }}
            getPopupContainer={(trigger) => trigger.parentNode}
          />
        </div>
        <div className="fix-over">
          <Select
            className="w-225px ms-3"
            size="large"
            getPopupContainer={(trigger) => trigger.parentNode}
            options={marketplaceList}
            onChange={(_, e) => {
              setMarketplaceSelected({
                marketplace: e?.marketplace,
                marketplace_id: e?.marketplace_id,
                region: e?.region,
                sales_channel: e?.sales_channel,
              });
              setSubCategoryLoading(true);
              GetSubCategoryAction({
                region: e?.region,
                sales_channel: e?.sales_channel,
              });
              setCategoryLoading(true);
              GetCategoryAction({
                region: e?.region,
                sales_channel: e?.sales_channel,
                account_type: e.account_type,
              });
              getCard({
                region: e?.region,
                sales_channel: e?.sales_channel,
                start: dateFilter?.start,
                advertising_filter: filters?.advertising_filter,
                category_filter: filters?.category_filter,
                end: dateFilter?.end,
                daterange_type: dateFilter?.daterange_type,
                account_type: e.account_type,
              });
            }}
            value={marketplaceSelected?.marketplace_id}
            loading={marketplaceLoading}
            placeholder="Select Marketplace"
          />
        </div>
      </div>
      <div
        className="mt-5 "
        style={{
          // display: "flex",
          // flexWrap: "wrap",
          // justifyContent: "space-between",

          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(291px, 1fr))",
          gap: "10px",
        }}
      >
        {cardLoading
          ? Array(12)
              ?.fill(1)
              ?.map((d, i) => (
                <div key={i} className="">
                  <Card
                    bordered={false}
                    style={{ width: "100%", minHeight: "200px" }}
                  >
                    <h2 style={{ textTransform: "capitalize" }}>
                      <Skeleton.Input active />
                    </h2>
                    <div className="d-flex align-items-center justify-content-between">
                      <h3
                        style={{
                          fontSize: 18,
                          color: "#6c6c6c",
                          fontWeight: 400,
                          textAlign: "center",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <Skeleton.Avatar active />
                      </h3>

                      <Skeleton.Avatar active />
                    </div>
                    <div
                      style={{
                        textAlign: "center",

                        padding: 1,
                        borderRadius: 10,

                        marginTop: "10px",
                      }}
                    >
                      {" "}
                      <Skeleton.Input active style={{ width: "100px" }} />
                    </div>
                  </Card>
                </div>
              ))
          : Object?.entries(cardData)?.map(([key, value], i) => {
              const minus = value?.previous_value < 0 ? "red" : "green";
              if (1 === 1) {
                return (
                  <div
                    // className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-12 mb-2 "
                    // className="col-auto"
                    key={i}
                    style={{
                      background: "#FFF",
                      padding: "20px",
                      borderRadius: "10px",
                    }}
                  >
                    <div className="d-grid">
                      <div className="d-grid">
                        <span
                          style={{
                            fontWeight: "500",
                            fontSize: "larger",
                            color: "#6D3057",
                            textTransform: "capitalize",
                          }}
                        >
                          {key?.split("_")?.join(" ") || "-"}
                        </span>
                        <span
                          style={{
                            fontWeight: "600",
                            fontSize: "x-large",
                            color: "#000",
                          }}
                        >
                          {console.log(key, "key")}
                          {SwapCommaAndDot(
                            parseFloat(
                              parseFloat(value?.value).toFixed(
                                sineView?.[key]?.d ? 2 : 0
                              ) || 0
                            )?.toLocaleString(),
                            sineView?.[key]?.p || "",
                            sineView?.[key]?.s || "",
                            marketplaceSelected?.marketplace_id
                          )}
                        </span>
                      </div>

                      <div
                        style={{
                          backgroundColor: "#F4E7E5",
                          padding: "9px 6px",
                          borderRadius: "7px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-evenly",
                        }}
                      >
                        <div className="d-grid me-3">
                          <span
                            style={{
                              color: "#2C7E10",
                              fontSize: "initial",
                              fontWeight: "500",
                            }}
                          >
                            {colorUpdate(
                              SwapCommaAndDot(
                                parseFloat(
                                  value?.previous_percentage
                                )?.toLocaleString(),
                                "",
                                "",
                                marketplaceSelected?.marketplace_id
                              )
                            )}
                          </span>
                          <span style={{ fontWeight: "100" }}>
                            Previous year
                          </span>
                        </div>
                        <div
                          style={{
                            background: "#B8B1B0",
                            width: 1,
                            height: 44,
                          }}
                        />

                        <div className="d-grid ms-3">
                          <span
                            style={{
                              color: "#2C7E10",
                              fontSize: "initial",
                              fontWeight: "500",
                            }}
                          >
                            {colorUpdate(
                              SwapCommaAndDot(
                                parseFloat(
                                  value?.period_percentage
                                )?.toLocaleString(),
                                "",
                                "",
                                marketplaceSelected?.marketplace_id
                              )
                            )}
                          </span>
                          <span style={{ fontWeight: "100" }}>
                            Previous Period
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <div key={i} className="col-xl-2 col-lg-4 col-6 mb-5">
                  <Card bordered={false} style={{ width: "100%" }}>
                    <h2
                      style={{
                        textTransform: "capitalize",
                        fontSize: "1.1rem",
                      }}
                    >
                      {key?.split("_")?.join(" ") || "-"}
                    </h2>
                    <div className="d-flex align-items-center justify-content-between">
                      <h3
                        style={{
                          fontSize: 18,
                          color: "#6c6c6c",
                          fontWeight: 400,
                          textAlign: "center",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <Tag color="#d26f5d" bordered={false}>
                          {SwapCommaAndDot(
                            parseFloat(
                              parseFloat(value?.value).toFixed(
                                sineView?.[key]?.d ? 2 : 0
                              ) || 0
                            )?.toLocaleString(),
                            sineView?.[key]?.p,
                            sineView?.[key]?.s,
                            marketplaceSelected?.marketplace_id
                          )}
                        </Tag>
                      </h3>

                      {value?.period_percentage && (
                        <Statistic
                          className="my-3"
                          value={`${SwapCommaAndDot(
                            parseFloat(
                              value?.period_percentage
                            )?.toLocaleString(),
                            "",
                            "",
                            marketplaceSelected?.marketplace_id
                          )}%`}
                          precision={2}
                          valueStyle={{
                            color: minus,
                            textAlign: "center",
                            fontSize: "14px",
                          }}
                          prefix={<ArrowUpOutlined />}
                          // suffix="%"
                        />
                      )}
                    </div>
                    {/* <div
                      style={{
                        textAlign: "center",
                        background: "#4169e112",
                        padding: 1,
                        borderRadius: 10,
                        border: "1px dashed royalblue",
                        marginTop: "10px",
                      }}
                    >
                      {" "}
                      <Statistic
                        className="my-3"
                        value={value?.previous_percentage}
                        precision={2}
                        valueStyle={{
                          color: "green",
                          textAlign: "center",
                          fontSize: "14px",
                        }}
                        prefix={<ArrowUpOutlined />}
                        suffix="%"
                      />
                    </div> */}
                  </Card>
                </div>
              );
            })}
      </div>
      <div className="card mt-5 p-5">
        <Table
          dataSource={tableData}
          columns={
            tableLoading
              ? []
              : Object?.keys(tableData?.[0] || {})?.map((d) => ({
                  title: (
                    <span style={{ textTransform: "capitalize" }}>
                      {d?.split("_")?.join(" ")}
                    </span>
                  ),
                  dataIndex: d,
                  key: d,
                  ...listExtraProps(d),
                  render: (e) => {
                    return (
                      <span>
                        {d === "month_and_year"
                          ? e
                          : SwapCommaAndDot(
                              parseFloat(e)?.toLocaleString(),
                              sineAdd?.[d]?.f || "",
                              sineAdd?.[d]?.l || "",
                              marketplaceSelected?.marketplace_id
                            )}
                      </span>
                    );
                  },
                }))
          }
          loading={tableLoading}
          pagination={false} // Disable pagination if you want
          scroll={{
            x: "max-content",
          }}
          onChange={handleOnChangeColumn}
        />
        <Pagination
          loading={tableLoading}
          pageSize={pageSize}
          pageSizeStatus={false}
          page={page}
          totalPage={totalPage}
          onPerPage={onPerPage}
          onPageNo={onPageNo}
        />
      </div>
      <div className="card mt-10 p-5">
        <div className="card-header">
          <div className="card-title"></div>
          <div className="card-toolbar">
            <div className="fix-over">
              <Select
                className="w-200px me-3"
                placeholder="Select filter type"
                maxTagCount={"responsive"}
                getPopupContainer={(target) => target.parentNode}
                size="large"
                showSearch={false}
                onChange={(e) => {
                  setLineFilter(e);

                  const nullObject = {};

                  for (const key of e) {
                    nullObject[key] = lineData?.series?.[key] || null;
                  }
                  setSelectedLineChartData(nullObject);
                }}
                value={lineFilter}
                mode="multiple"
                options={[
                  {
                    label: "Adv Clicks",
                    value: "adv_clicks",
                  },
                  {
                    label: "Adv Click-Thru Rate",
                    value: "adv_click-Thru_rate",
                  },
                  {
                    label: "Adv Spend",
                    value: "adv_spend",
                  },
                  {
                    label: "Adv Impressions",
                    value: "adv_impressions",
                  },
                  {
                    label: "Adv Revenue",
                    value: "adv_revenue",
                  },
                  // {
                  //   label: "Overall Revenue",
                  //   value: "all_sales",
                  // },
                  {
                    label: "Orders",
                    value: "orders",
                  },
                  {
                    label: "Orders Revenue",
                    value: "order_revenue",
                  },
                  // {
                  //   label: "Sales Percent",
                  //   value: "sales_percent",
                  // },
                ]}
              />
            </div>
          </div>
        </div>

        {/* {lineLoading ? (
          <Spin />
        ) : (
          <ReactApexChart
            options={chartOptions}
            series={Object.entries(selectedLineChartData)?.map(
              ([key, value]) => {
                return {
                  data: value,
                  name: capitalizeFirstLetter(key?.split("_")?.join(" ")),
                };
              }
            )}
            type="area"
            height={350}
          />
        )} */}

        {lineLoading ? (
          <Spin />
        ) : Object.values(selectedLineChartData || {})?.length === 0 ? (
          <Empty />
        ) : (
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: "spline",
              },
              title: {
                text: "",
              },
              xAxis: {
                categories: lineData?.label,
              },
              yAxis: [
                {
                  title: {
                    text: "",
                  },
                  labels: {
                    formatter: function () {
                      return SwapCommaAndDot(
                        parseFloat(this.value || 0).toLocaleString(),
                        sign,
                        "",
                        marketplaceSelected?.marketplace_id?.split("_")?.[0]
                      );
                    },
                  },
                },
                {
                  // Second yAxis for percentage values
                  title: {
                    text: "",
                  },
                  labels: {
                    formatter: function () {
                      return `${SwapCommaAndDot(
                        parseFloat(this.value || 0).toLocaleString(),
                        "",
                        "",
                        marketplaceSelected?.marketplace_id?.split("_")?.[0]
                      )}%`;
                    },
                  },
                  opposite: true,
                },
                {
                  title: {
                    text: "",
                  },
                  labels: {
                    formatter: function () {
                      return `${SwapCommaAndDot(
                        parseFloat(this.value || 0).toLocaleString(),
                        "",
                        "",
                        marketplaceSelected?.marketplace_id?.split("_")?.[0]
                      )}`;
                    },
                  },
                  opposite: true,
                },
              ],
              tooltip: {
                shared: true,
                shadow: false,
                style: {
                  // Apply the CSS class to the tooltip
                  className: "highcharts-tooltip",
                },
                useHTML: true,
                style: {
                  padding: 0,
                  pointerEvents: "none",
                },
                formatter: function () {
                  const colors = this.points.map((p) => p.color);
                  const values = this.points.map((p) =>
                    p.series.yAxis.userOptions.labels.formatter.call({
                      value: p.y,
                    })
                  );
                  return `
                    <div class="apexcharts--tooltip" style="zoom: 110%;">
                      <div class="apexcharts--tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 10px;">${
                        this.x
                      }</div>
                      <div class="apexcharts--tooltip-series-group">
                        ${this.points
                          .map(
                            (p, i) => `
                              <div class="apexcharts--tooltip-series">
                                <div class="apexcharts--tooltip-marker" style="background: ${colors[i]}"></div>
                                <span class="apexcharts--tooltip-text">${p.series.name}</span>&nbsp;:
                                <b class="apexcharts--tooltip-value">${values[i]}</b>
                              </div>
                            `
                          )
                          .join("")}
                      </div>
                    </div>
                  `;
                },
              },
              plotOptions: {
                series: {
                  marker: {
                    enabled: false, // Disable markers (line dots) globally
                  },
                  dataLabels: {
                    enabled: false,
                    borderWidth: 1,
                    borderColor: "gray",
                    backgroundColor: "white",
                    padding: "2px 4px", // Optional: Adjust padding around the text within the box
                    formatter: function () {
                      return this.y; // Display the data value as the label
                    },
                  },
                },
              },
              series: Object.entries(selectedLineChartData)?.map(
                ([key, value], i) => {
                  return {
                    data: value,
                    color: [
                      "#FF6969",
                      "#0C1844",
                      "#83B4FF",
                      "#E88D67",
                      "#3AA6B9",
                      "#7469B6",
                      "#803D3B",
                    ]?.[i],
                    yAxis:
                      findSign?.[key] === ""
                        ? 2
                        : findSign?.[key] === "%"
                        ? 1
                        : 0,
                    name: capitalizeFirstLetter(key?.split("_")?.join(" ")),
                    type:
                      findSign?.[key] !== "" && findSign?.[key] !== "%"
                        ? "column"
                        : "spline",
                    tooltip:
                      findSign?.[key] === "%"
                        ? {
                            valueSuffix: "%",
                          }
                        : findSign?.[key] !== "" && {
                            valuePrefix: sign,
                          },
                  };
                }
              ),
            }}
          />
        )}
      </div>
      <div className="row mt-10">
        <div className="col-6">
          <div className="card">
            <div className="card-header">
              <div className="card-title"></div>
              <div className="card-toolbar">
                <div className="fix-over">
                  <Select
                    className="w-200px me-3"
                    placeholder="Select filter type"
                    size="large"
                    getPopupContainer={(target) => target.parentNode}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        category_filter: e,
                      });
                      getCardGraph({
                        region: marketplaceSelected?.region,
                        sales_channel: marketplaceSelected?.sales_channel,
                        start: dateFilter?.start,
                        advertising_filter: filters?.advertising_filter,
                        category_filter: e,
                        end: dateFilter?.end,
                        daterange_type: dateFilter?.daterange_type,
                      });
                    }}
                    value={filters?.category_filter}
                    allowClear
                    options={[
                      {
                        label: "Adv Revenue",
                        value: "adv_revenue",
                      },
                      {
                        label: "Adv Spend",
                        value: "adv_spend",
                      },
                      {
                        label: "Adv Impressions",
                        value: "adv_impressions",
                      },
                      {
                        label: "Adv Click",
                        value: "adv_clicks",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="card-body">
              <PieChart
                marketplaceSelected={marketplaceSelected}
                data={piechartData?.categoryAdvertisingData || {}}
              />
            </div>
          </div>
        </div>

        <div className="col-6">
          <div className="card">
            <div className="card-header">
              <div className="card-title"></div>
              <div className="card-toolbar">
                <div className="fix-over">
                  <Select
                    className="w-200px me-3"
                    placeholder="Select filter type"
                    size="large"
                    getPopupContainer={(target) => target.parentNode}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        advertising_filter: e,
                      });
                      getCardGraph({
                        region: marketplaceSelected?.region,
                        sales_channel: marketplaceSelected?.sales_channel,
                        start: dateFilter?.start,
                        advertising_filter: e,
                        category_filter: filters?.category_filter,
                        end: dateFilter?.end,
                        daterange_type: dateFilter?.daterange_type,
                      });
                    }}
                    value={filters?.advertising_filter}
                    allowClear
                    options={[
                      {
                        label: "Adv Revenue",
                        value: "adv_revenue",
                      },
                      {
                        label: "Adv Spend",
                        value: "adv_spend",
                      },
                      {
                        label: "Adv Impressions",
                        value: "adv_impressions",
                      },
                      {
                        label: "Adv Click",
                        value: "adv_clicks",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
            <div className="card-body">
              <PieChart
                marketplaceSelected={marketplaceSelected}
                title={filters?.advertising_filter}
                data={piechartData?.advertisingData || {}}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Analytics;
