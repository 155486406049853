import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { Button, Select, Table, Tag, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { AllMarketPlace, SwapCommaAndDot } from "../../../../config";

export default function ResellerMonitor(props) {
  const {
    SalesChannelListAction,
    ItemsOfferGetResellerList,
    ItemsOfferGetResellerProductList,
    fakeActionCatalog,
  } = props;
  const SalesChannelListResponse = useSelector(
    (state) => state.Catalog.SalesChannelListResponse || {}
  );
  const ItemsOfferGetResellerListResponse = useSelector(
    (state) => state.Catalog.ItemsOfferGetResellerListResponse || {}
  );
  const ItemsOfferGetResellerProductListResponse = useSelector(
    (state) => state.Catalog.ItemsOfferGetResellerProductListResponse || {}
  );

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const [productList, setProductList] = useState([]);
  const [productLoading, setProductLoading] = useState(true);

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
    account_type: null,
  });

  useEffect(() => {
    if (ItemsOfferGetResellerProductListResponse?.status === true) {
      setProductList(ItemsOfferGetResellerProductListResponse?.data || []);
      setProductLoading(false);

      fakeActionCatalog("ItemsOfferGetResellerProductListResponse");
    } else if (ItemsOfferGetResellerProductListResponse?.status === false) {
      setProductList([]);
      setProductLoading(false);
      fakeActionCatalog("ItemsOfferGetResellerProductListResponse");
    }
  }, [ItemsOfferGetResellerProductListResponse]);
  useEffect(() => {
    if (ItemsOfferGetResellerListResponse?.status === true) {
      setList(ItemsOfferGetResellerListResponse?.data || []);
      setLoading(false);

      if (ItemsOfferGetResellerListResponse?.data?.length > 0) {
        setProductLoading(true);
        const { region, account_type, sales_channel } = marketplaceList
          .flatMap((entry) => entry.options || [])
          ?.filter(
            (d) =>
              d?.marketplace_id?.split("_")?.[0] ===
              ItemsOfferGetResellerListResponse?.data?.[0]?.marketplace_id
          )?.[0];

        const obj = {
          region: region,
          account_type: account_type,
          sales_channel: sales_channel,
          seller_id: ItemsOfferGetResellerListResponse?.data?.[0]?.seller_id,
        };
        ItemsOfferGetResellerProductList(obj);
      } else {
        setProductLoading(false);
        setProductList([]);
      }

      fakeActionCatalog("ItemsOfferGetResellerListResponse");
    } else if (ItemsOfferGetResellerListResponse?.status === false) {
      setList([]);
      setLoading(false);
      fakeActionCatalog("ItemsOfferGetResellerListResponse");
    }
  }, [ItemsOfferGetResellerListResponse]);

  useEffect(() => {
    if (SalesChannelListResponse?.status === true) {
      if (SalesChannelListResponse?.data?.records?.length > 0) {
        const salesList = SalesChannelListResponse?.data?.records?.reduce(
          (acc, e) => {
            const regionIndex = acc.findIndex(
              (r) => r.label === e.region_label
            );

            if (regionIndex === -1) {
              acc.push({
                label: e.region_label,
                options: [
                  {
                    label: (
                      <>
                        <img
                          src={`/domainImage/${e?.marketplace_flag}.png`}
                          style={{ height: "20px" }}
                          loading="lazy"
                          className="me-3"
                          onError={(e) => {
                            e.target.src = "/media/domainImage/red-flag.png";
                          }}
                        />
                        {e?.region_label === "Ecommerce"
                          ? "Ecommerce"
                          : `Amazon.${e?.account_type || ""}.${
                              e?.short_name || ""
                            }`}
                        {/* {`Amazon.${e?.account_type || ''}.${e?.short_name || ''}`} */}
                      </>
                    ),
                    value: e.marketplace_id,
                    region: e.region,
                    marketplace_id: e?.marketplace_id,
                    marketplace: e?.marketplace,
                    sales_channel: e?.sales_channel,
                    account_type: e?.account_type,
                  },
                ],
              });
              if (e?.default_marketplace === 1) {
                setMarketplaceSelected({
                  marketplace: e?.marketplace,
                  marketplace_id: e.marketplace_id,
                  region: e.region,
                  sales_channel: e.sales_channel,
                  account_type: e?.account_type,
                });
                setLoading(true);
                ItemsOfferGetResellerList({
                  ...{
                    region: e?.region,
                    account_type: e?.account_type,
                    sales_channel: e?.sales_channel,
                  },
                });
              }
            } else {
              acc[regionIndex].options.push({
                label: (
                  <>
                    <img
                      src={`/domainImage/${e?.marketplace_flag}.png`}
                      style={{ height: "20px" }}
                      loading="lazy"
                      className="me-3"
                      onError={(e) => {
                        e.target.src = "/media/domainImage/red-flag.png";
                      }}
                    />
                    {`Amazon.${e?.account_type || ""}.${e?.short_name || ""}`}
                  </>
                ),
                value: e.marketplace_id,
                marketplace_id: e?.marketplace_id,
                region: e.region,
                marketplace: e?.marketplace,
                sales_channel: e?.sales_channel,
                account_type: e?.account_type,
              });
            }
            return acc;
          },
          []
        );

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);
        setMarketplaceLoading(false);
        setMarketplaceList([AllMarketPlace, ...uniqueList]);
      } else {
        setMarketplaceLoading(false);
        setMarketplaceList([]);
      }
      setMarketplaceLoading(false);
      fakeActionCatalog("SalesChannelListResponse");
    } else if (SalesChannelListResponse?.status === false) {
      setMarketplaceLoading(false);
      setMarketplaceList([]);

      fakeActionCatalog("SalesChannelListResponse");
    }
  }, [SalesChannelListResponse]);

  useEffect(() => {
    setMarketplaceLoading(true);
    SalesChannelListAction();

    return () => {};
  }, []);

  const columns = [
    {
      title: "Reseller ID",
      dataIndex: "seller_id",
      key: "seller_id",
      render: (text, row) => (
        <Tooltip title="Click to view details">
          <Button
            onClick={() => {
              const { region, account_type, sales_channel } = marketplaceList
                .flatMap((entry) => entry.options || [])
                ?.filter(
                  (d) =>
                    d?.marketplace_id?.split("_")?.[0] === row?.marketplace_id
                )?.[0];

              const obj = {
                region: region,
                account_type: account_type,
                sales_channel: sales_channel,
                seller_id: row?.seller_id,
              };
              setProductLoading(true);
              setProductList([]);

              ItemsOfferGetResellerProductList(obj);
            }}
            type="dashed"
          >
            <span
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "#0958d9",
              }}
            >
              {text}
            </span>
          </Button>
        </Tooltip>
      ),
    },
    {
      title: "Reseller Name",
      dataIndex: "seller_name",
      key: "seller_name",
      render: (text) => {
        return text || "-";
      },
    },
    {
      title: "Total Products",
      dataIndex: "product_count",
      align: "right",
      key: "product_count",
    },
  ];
  return (
    <Wrapper className="p-5 fadeInBottom">
      <div className="row justify-content-end mb-3">
        <div className="col-auto">
          <Select
            className="w-225px me-3"
            size="large"
            getPopupContainer={(target) => target.parentNode}
            options={marketplaceList}
            onChange={(_, e) => {
              setMarketplaceSelected({
                marketplace: e?.marketplace,
                marketplace_id: e?.marketplace_id,
                region: e?.region,
                sales_channel: e?.sales_channel,
                account_type: e?.account_type,
              });
              setLoading(true);
              setList([]);
              setProductList([]);
              setProductLoading(true);
              ItemsOfferGetResellerList({
                ...{
                  region: e?.region,
                  sales_channel: e?.sales_channel,
                },
              });
            }}
            value={marketplaceSelected?.marketplace_id}
            loading={marketplaceLoading}
            placeholder="Select Marketplace"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-5">
          <div className="card">
            <div className="card-header ">
              <div className="card-title">
                <h3 className="m-0">Reseller List</h3>
              </div>
            </div>
            <div className="card-body pt-5">
              <Table
                dataSource={list}
                columns={columns}
                loading={loading}
                scroll={{ x: "max-content" }}
              />
            </div>
          </div>
        </div>
        <div className="col-7">
          <div className="card">
            <div className="card-header ">
              <div className="card-title">
                <h3 className="m-0">Reseller Product List</h3>
              </div>
            </div>
            <div className="card-body pt-5">
              <Table
                loading={productLoading}
                dataSource={productList?.items || []}
                columns={[
                  {
                    title: "SKU",
                    dataIndex: "sku",
                    key: "sku",
                    render: (e) => {
                      return (
                        <Tag color="blue" bordered={false}>
                          {e || "-"}
                        </Tag>
                      );
                    },
                  },
                  {
                    title: "Product Name",
                    dataIndex: "product_name",
                    key: "product_name",
                    width: 450,
                    render: (e) => {
                      return e || "-";
                    },
                  },
                  {
                    title: "Price",
                    dataIndex: "listing_price",
                    key: "listing_price",
                    render: (e) => {
                      return (
                        <b>
                          {SwapCommaAndDot(
                            parseFloat(e || 0)?.toLocaleString(),
                            productList?.currency || "",
                            "",
                            marketplaceSelected?.marketplace_id
                          )}
                        </b>
                      );
                    },
                  },
                ]}
                scroll={{ x: "max-content" }}
                // pagination={false}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
