import { createGlobalStyle } from "styled-components";

const AppStyleWrapper = createGlobalStyle`
  body {
    background-color: rgb(244 247 254);
  }
  .ant-image-img {
    object-fit: contain;
  }

  html {
    overflow-y: hidden;
    overflow-x: hidden;
    scroll-behavior: smooth;
    &:hover {
      overflow-y: overlay;
      overflow-x: overlay;
    }
  }
  .ant-input-affix-wrapper {
    border-color: #601947;
    background-color: #fef4f3;
  }
  .shimmer {
    -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
      100%;
    
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
  }

  @keyframes shimmer {
    100% {
      -webkit-mask-position: left;
    }
  }

  .overlayLoading {
    margin: auto;
    width: 30%;
    height: fit-content;
    inset: 0;
    position: absolute;
    z-index: 99999999;
  }

  .sticky-column {
    position: sticky;
    left: 0;
    z-index: 9;
    background-color: #fff !important;
  }

  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: linear-gradient(
      to right bottom,
      rgb(255 255 255),
      #ffffff
    ) !important;
  }

  .ant-tooltip-inner {
    background-color: rgb(255 255 255) !important;
    font-weight: 500;
    color: #000 !important;
  }

  .form-control:disabled,
  .form-control[readonly] {
    background-color: #eff2f5 !important;
    opacity: 1;
  }

  .border-dashed-all {
    border: 1px dashed #cfd4d9 !important;
  }
  ::-webkit-scrollbar {
    width: 12px !important;
    height: 12px !important;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: transparent;
    background-color: #e7e7e7;
    border: 2px solid #cacaca;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
    background-color: #e9805f !important;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #5c1944 !important;
  }
  .ant-table-content,
  .ant-table-body,
  .gridScroll,
  .notificationList,
  .configGrid,
  .dashboardProfitScroll,
  .companyEmpScroll,
  .ant-upload-list,
  #kt_aside_menu_wrapper,
  .asinDetailsProfitList,
  .sidebar_asinwiser,
  #dashboard-product-card,
  #kt_wrapper {
    &::-webkit-scrollbar {
      width: 12px !important;
      height: 12px !important;
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: transparent !important;
      border: 2px solid transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: 3px solid transparent !important;
      background-clip: content-box;
      background-color: transparent !important;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #5c1944 !important;
    }
    &:hover {
      &::-webkit-scrollbar {
        width: 12px !important;
        background-color: transparent;
      }

      &::-webkit-scrollbar-track {
        border-radius: 8px;
        background-color: transparent;
        background-color: #e7e7e7;
        border: 2px solid #cacaca;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        border: 3px solid transparent;
        background-clip: content-box;
        background-color: #e9805f !important;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #5c1944 !important;
      }
    }
  }
  .configGrid {
    overflow: auto;
    max-height: 420px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #7e78ef1c;
    border-radius: 0.65rem;
    border: 1px solid #5f1a46 !important;
    color: #000;
  }

  .ant-select {
    .ant-select-selector {
      background-color: rgb(254 243 242) !important;
      border-radius: 0.65rem;
      border: 1px solid #7e78ef52 !important;
      color: #000;
    }

    .ant-select-selection-placeholder {
      color: #5e6278;
    }
    .ant-select-suffix {
      color: #5e6278;
    }
  }
  .text-align-end {
    text-align: end;
  }
  .ant-input-affix-wrapper {
    // background-color: #7e78ef1c;
    border-radius: 0.65rem;
    // border: 1px solid #7e78ef52 !important;
    color: #000;
  }

  .ant-picker {
    background: #e8f0fe !important;
  }

  .ant-input-password {
    input {
      font-family: inherit !important;
      font-size: 1.15rem !important;
      font-weight: 500 !important;
      background: #e8f0fe !important;
    }
  }

  .form-control.form-control-solid {
    border-radius: 0.65rem;
    background-color: #e8f0fe !important;
  }

  .filterFooter {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap !important;
    width: 100% !important;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: 1px dashed !important;
    border-top: none !important;
    border-color: #e4e6ef !important;
    background: white;
  }

  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    background-color: transparent !important;
  }

  .ant-picker {
    background: #e8f0fe !important;
    border: 1px solid transparent !important;
    border-radius: 0.65rem !important;
    .ant-picker-active-bar {
      background: #7e78ef !important;
    }
  }

  :where(.css-dev-only-do-not-override-1l14yfp).ant-input-affix-wrapper {
    background-color: #7e78ef1c !important;
  }

  .ant-input-affix-wrapper {
    padding: 0px 3px !important;
    input {
      background-color: transparent !important;
    }
  }

  .ant-input-affix-wrapper {
    height: 39.35px;
    // background-color: #7e78ef1c;
    padding: 0px 10px !important;
  }

  .ant-tooltip-arrow:before {
    background: white !important;
  }

  .ant-switch {
    min-width: 35px !important;
    height: 24px !important;
    background: rgba(0, 0, 0, 0.25) !important;
    .ant-switch-handle {
      top: 3px !important;
    }
  }

  .ant-switch-checked {
    background: #c5655a !important;
  }

  .ant-tag {
    cursor: default;
  }

  .ant-select-selector {
    background-color: #7e78ef1c;
    border-radius: 0.65rem;
    border: 1px solid #7e78ef52 !important;
    color: #000;
  }


  #kt_wrapper {
    overflow: auto;
    height: 100%;
    padding-top: 20px;
    position: relative;
    top: -20px;
  }
  .ant-upload-list-item-name {
    color: #7e78ef;
  }

  #kt_advanced_search_form {
    .title {
      font-weight: 700;
      font-size: 12px;
    }
    .sign {
      padding: 8px 10px;
      background: #e8f0fe;
    }
    
    .ant-input {
      width: 100px;
      text-align: center;
      background: #e8f0fe;
      border: none;
      &:hover {
        border-color: #7e78ef52 !important;
      }
    }

    .ant-radio-group-solid
      :where(
        .css-dev-only-do-not-override-acm2ia
      ).ant-radio-button-wrapper-checked:not(
        :where(
            .css-dev-only-do-not-override-acm2ia
          ).ant-radio-button-wrapper-disabled
      ) {
      color: #fff !important;
      background: #7e78ef !important;
      border-color: #7e78ef !important;
    }

    .ant-radio-button-wrapper:hover {
      color: #7e78ef !important;
      // font-weight: bold;
    }
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    // background-color: #f5f8fa;
    background-color: #7e78ef1c;
    border-radius: 0.65rem;
    border: 1px solid #5f1a46 !important;
    color: #000;
  }
  .ant-select-clear {
    background: #f1f0fd !important;
  }
  .ant-picker-clear {
    background: #d8d5fa !important;
  }
  .ant_common_input {
    background: #7e78ef1c !important;
    color: #212124;
    border: 1px solid #cccaf9 !important;
  }
  .ant_common_textarea {
    background: #7e78ef1c !important;
    color: #212124;
    border: 1px solid #cccaf9 !important;
    textarea {
    }
  }
  .ant_common_input::placeholder {
    color: #5e6278;
  }

  .filter_input::placeholder {
    color: #5e6278;
  }
  .main_header_btn {
    background-image: linear-gradient(
      90deg,
      rgb(95, 90, 197),
      rgb(159, 154, 234)
    ) !important;
    color: rgb(255, 255, 255) !important;
  }
  .main_header_input {
    background-color: rgb(255 255 255) !important;
    border-color: rgb(160 219 212) !important;
  }
  .ant-tooltip-inner {
    color: #000 !important;
    a {
      color: #000 !important;
    }
  }

  .pricingsModal {
    table {
      svg {
        transition: 0.7s;
        cursor: pointer;
        path:first-child {
          fill: #faada545;
        }
        path:last-child {
          fill: #ee7ab1;
        }
        &:hover {
          path:first-child {
            fill: #faada5;
          }
        }
      }
    }
  }
  .ant-btn-primary {
    background-color: #7e78ef;
  }
  a[disabled] {
    opacity: 0.6;
  }
  input {
    caret-color: #ff9678;
  }
  ::selection {
    color: #5c1944;
    background: #f8dad2;
  }
  body > #root {
    height: 100%;
  }
  body > #root > .globalHeight {
    height: 100%;
  }
  .ant-menu-submenu-popup {
    .menu-bullet {
      vertical-align: 2px !important;
    }
  }
  .ant-menu-inline-collapsed {
    .ant-menu-item-icon {
      position: absolute !important;
      inset: 0 !important;
      margin: auto !important;
      width: fit-content !important;
    }
  }

  .ant-table-sticky-scroll {
    display: none !important;
  }
  #webpack-dev-server-client-overlay {
    display: none;
  }
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100% !important;
    height: 100% !important;
    background-color: #000;
  }
  #kt_wrapper > div {
    position: relative;
    .overlayLoading {
      svg {
        width: 80px;
        margin-bottom: 10px;
      }
    }
  }
  .overlayLoadingRoot {
    svg {
      width: 80px;
      margin-bottom: 10px;
    }
  }
  .ant-input-group-wrapper-status-error .ant-input-group-addon {
    color: #000 !important;
    border-color: #cdcaf9 !important;
  }

  .line-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .drawer {
    transition: height 1s, transform 1s ease-out;
  }
  .drawer.close {
    transform: translateY(50vh);
    height: 0px !important;
    padding: 0px !important;
    position: absolute;
  }
  .drawer.close * {
    visibility: hidden;
    transition: visibility 1s;
  }
  .drawer.open {
    transform: translateY(0vh);
    height: calc(100% - 10px);
  }
  .drawer.open * {
    visibility: visible;
  }
  #kt_advanced_search_form .title {
    font-weight: 600;
    font-size: 1.05rem !important;
  }
  img {
    pointer-events: none;
  }
  .antd_selected_presets_date {
    color: #fff;
    background: #7e78ef;
    &:hover {
      color: #7e78ef;
    }
  }

  .asinCommonIcon {
    // transition-duration: 0.2s !important;
    cursor: pointer;
  }
  .asinCommonIcon :hover {
    transform: scale(1.2);
    opacity: 0.8;
    transition-timing-function: linear;
    transition-duration: 0.2s !important;
  }

  .ant-upload-icon {
    .anticon {
      color: #7e78ef !important;
    }
  }
  .ant-upload-list-item-name {
    color: #7e78ef !important;
  }
  .ant-upload-list-item-error {
    pointer-events: none;
    .ant-upload-list-item-actions {
      pointer-events: auto;
    }
  }
  .ant-notification-notice-closable {
    background-image: linear-gradient(211deg, #ed76b300, #ff000026) !important;
    background: #f9efed !important;
    box-shadow: 0px 0px 20px 20px #0000000d !important;
    border: 1px dashed #faaea5 !important;
    border-radius: 20px !important;
  }

  .notificationList {
    background: #fff;
    min-height: 210px;
    overflow-x: hidden !important;
    max-height: 400px;
    overflow: auto;
    margin-top: 10px;
    // margin : 10px 0px;
    .notification {
      // background: #dde1e6;
      background:#edf3fb;
      height: 117px;
      border: 1px dashed #7e78ef;
      margin-bottom: 10px;
      border-radius: 7px;
      padding: 0px 10px;
      margin-left: 10px;
      margin-right: 5px;
      transition: 0.6s;
      // cursor: pointer;
      &:first-child {
        margin-top: 0px;
      }
      &:hover {
        border: 1px solid #7e78ef;
        box-shadow: 0 0 20px 0 rgb(126 120 239 / 65%);
        ${"" /* transform: scale(1.01); */}
      }
    }
    .offerChangeDate {
      display: flex;
      justify-content: flex-end;
      position: relative;
      top: -13px;
      font-weight: 900;
      color: #7e78ef;
    }
  }
  .asinwiser_create_btn {
    .btn-primary:before {
      content: "Add";      
    }
  }
  .ant-menu-vertical {
    border-inline-end: none !important;
  }
  .pageIconBlock {
    display: none;
  }
  .pageNameBlock {
    display: block;
  }
  @media only screen and (max-width: 500px) {
    .pageIconBlock {
      display: block;
    }
    .pageNameBlock {
      display: none;
    }
    .master_table_brand_root_search,
    .master_table_root_filter,
    .repricing_product_root_filter,
    .sales-analytics-root-input,
    .sp-api-reports-root-input,
    .root-input {
      .position-relative {
        width: 100%;
        .main_header_input {
          width: 100% !important;
        }
      }
    }
    .master_table_toolbar_filter {
      width: 100%;
      div {
        width: 100%;
        .ant-select {
          width: 70% !important;
        }
        .btn-primary {
          width: auto !important;
        }
      }
    }

    .master_table_sku_toolbar_filter {
      width:100% !important;
      .position-relative{
        width: 100% !important;
        .ant-select{
          width: 100% !important;   
        }
      }
      // div:first-child {
      //   width: 100%;
      //   .ant-select {
      //     width: 65% !important;
      //   }
      // }
    }

    .manage_report_toolbar_filter{
      width:100% !important;
      .position-relative{
        width: 100% !important;
        .ant-select{
          width: 100% !important;   
        }
      }
    }

    .app_log_toolbar_filter{
      width:100% !important;
      .position-relative{
        width: 100% !important;
        .ant-select{
          width: 100% !important;   
        }
      }
    }


    .sales_analytics_toolbar{
      width:100% !important;
      .position-relative{
        width: 100% !important;
        .ant-select{
          width: 100% !important;   
        }
        .ant-picker{
          width: 100% !important;   
        }
      }
    }

    .replenishment_filter_toolbar{
      width:100% !important;
      .flex-stack{
        width:100% !important;
      }
      .position-relative{
        width: 100% !important;
        .ant-select{
          width: 100% !important;   
        }
        .ant-picker{
          width: 100% !important;   
        }
      }
    }


    .repricing_product_filter {
      width: 100%;
      // justify-content: center;
      .position-relative {
        // margin-right: 9px !important;
      }
    }
    .asinwiser_create_btn {
      .btn-primary:before {
        content: "";
      }
      .btn-primary:after {
        content: "+";      
        font-size: 16px !important;
      }
    }
    .inventory-filter {
      .position-relative:first-child {
        width: 100%;
        margin: 0px !important;
        .ant-select {
          width: 100% !important;
        }
      }
      .position-relative:last-child {
        width: 100%;
        .ant-picker {
          width: 100% !important;
        }
      }
    }

    .profitability-filter {
      .bg-white {
        width: 100%;
        .position-relative:first-child {
          width: 100%;
          .ant-select {
            width: 100% !important;
          }
        }
        .position-relative:nth-child(2) {
          width: 65%;
          .ant-input-affix-wrapper {
            width: 100% !important;
          }
        }
      }
     
    }
    .filter_estimated_sales {
      div:first-child {
        width: 100%;
        .position-relative {
          width: 100%;
          .ant-select {
            width: 100% !important;
          }
        }
      }
      .card-toolbar{
        .position-relative {
          width: 60%;
          .ant-select {
            width: 100% !important;
          }
        }
      }
    }

    

    .central-log-filter-admin {
      .position-relative:nth-child(2),
      .position-relative:nth-child(3),
      .position-relative:nth-child(4),
      .position-relative:nth-child(1) {
        width: 48%;
        .ant-select {
          width: 100% !important;
        }
      }
      .card-toolbar{
        width: 100% !important;
        .d-flex{
          width: 100% !important;
          .ant-select {
            width: 100% !important;
          }
        }
       
      }
    }
    .central-log-user-filter {
     
      .position-relative:nth-child(2),
      .position-relative:nth-child(4),
      .position-relative:nth-child(3),
      .position-relative:nth-child(5) {
        width: 48%;
        .ant-select {
          width: 100% !important;
        }
      }
      .position-relative:nth-child(1), {
        width: 100%;
        .ant-select {
          width: 100% !important;
        }
      }
    }

    .wholesale_list_suppliers_filters {
      .position-relative:first-child {
        width: 100%;
        .ant-select {
          width: 100% !important;
        }
      }
      .position-relative:nth-child(2) {
        width: 80%;
      }
    }
    .product_research_filter_toolbar {
      width: 100%;
      .flex-stack {
        width: 100%;
      }
      .ant-select {
        width: auto !important;
      }
    }
    .product_research_filter_outerFilter {
      .position-relative {
        width: 100% !important;
        .ant-select {
          width: 100% !important;
        }
      }
    }
    .dashboard_filter_toolbar {
      .position-relative {
        width: 100% !important;
        margin-right: 0rem !important;
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
        .ant-select,
        .ant-picker {
          width: 100% !important;
        }
      }
      .btn-primary {
        margin-right: 10px !important;
      }
      .ms-4 {
        margin-left: 0rem !important;
      }
    }
    .competitor_research_filter_toolbar {
      width: 100%;
      .flex-stack {
        width: 100%;
        #kt_horizontal_search_advanced_link {
          width: 40%;
        }
        .position-relative {
          width: 55%;
          .ant-select {
            width: 100% !important;
          }
        }
      }
    }
    .competitor_research_filter_outerFilter {
      .position-relative {
        width: 100% !important;
        .ant-select {
          width: 95% !important;
        }
        #newSearch {
          width: 100% !important;
        }
      }
    }
    .competitor_research_filter_and_button_root {
      margin-left: 0 !important;
      width: 100% !important;
      .text-start {
        padding: 0px 0px !important;
        .position-relative {
          width: 100%;
          .main_header_input {
            width: 100% !important;
          }
        }
      }
    }
    .product_research_filter_and_button_root {
      margin-left: 0 !important;
      width: 100% !important;
      .text-start {
        padding-left: 0px !important;
      }
      .text-end {
        padding-right: 0 !important;
      }
      .col-sm-6 {
        width: 50% !important;
        justify-content: space-between;
        #newSearch {
          width: 100% !important;
        }
        .main_header_btn {
          padding: 12px 0px !important;
          margin-right: 0px !important;
          width: 100% !important;
        }
      }
    }
    .product_ressearch_details_filter {
      .all-search {
        width: calc(100vw - 90px) !important;
      }
      .col-md-6 > .flex-wrap {
        align-items: center;
        justify-content: flex-start;
      }
    }
    .wholesales_root_filter {
      .flex-wrap {
        width: 100%;
        .position-relative:first-child {
          width: 50%;
          .ant_common_input {
            width: 100% !important;
          }
        }
        .position-relative:last-child {
          width: 15%;
        }
        .ant-select {
          width: 100% !important;
        }
      }
    }

    .wholesales_root_filter_admin {
      // width:100% !important;
      .d-flex{
        width: 100% !important;
      }
      .flex-wrap {       
        gap:1rem !important;
        .pageSearch{
          width: 60%;
          .ant_common_input {
            width: 100% !important;
          }
        }
        .position-relative:last-child {
          width: 10%;
        }
      }
      .card-toolbar{
        width:100% !important;
        .position-relative{
          width:60% !important;
          .ant-select {
            width: 100% !important;
          }
        }
      }
    }


    .listing-optimizer-toolbar{
      width: 100% !important;
      .position-relative{
        width: 70% !important;
        .ant-select {
          width: 100% !important;
          padding: 3px 0px !important;
          // margin : 0px !important
        }
      } 
    }

    .brand-wise-analytics-header{
      .position-relative{
        width: 48% !important;
        .ant-select {
          width: 100% !important;
        }
        .ant_common_input{
          width: 100% !important;
        }
      }
    }

    .brand_wise_card_toolbar{
      width: 100% !important;
      .position-relative{
        width: 100% !important;
        .ant-select {
          width: 100% !important;
        }          
      }
    }

    .whole_sale_supplier_modal{
      display:grid !important;
      span{        
        margin:0px!important;
      }
    }
  }
  ${"" /* end */}
  ${"" /*  Start*/}
  @media only screen and (max-width: 340px) {
    .brand-wise-analytics-header{
      .position-relative{
        width: 100% !important;
        .ant-select {
          width: 100% !important;
        }
        .ant_common_input{
          width: 100% !important;
        }
      }
    }
    .product_research_filter_toolbar {
      width: 100%;
      .btn-light-primary{
        width: 100% !important;
      }
      .ant-select {
        margin-top:2px;
        width: 100% !important;
      }
    }

    .listing-optimizer-toolbar{
      .position-relative{
        width: 70% !important;
        .ant-select {
          width: 100% !important;
          padding: 3px 0px !important;
          // margin : 0px !important
        }
      }
      .position-relative:nth-child(2){
        width: 100% !important;
        .ant-select {
          width: 100% !important;
          padding: 3px 0px !important;
          // margin : 0px !important
        }
      }
     
    }
  }
  ${"" /* end */}
${"" /* animation */}

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .fade-in {
    opacity: 0;
    animation: fadeIn ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
  }

  .authFadeInBottom {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: authFadeInBottom;
    z-index : 99
  }

  @keyframes authFadeInBottom {
    from {
      opacity: 0;
      transform: translateY(-25%);
    }
    to {
      opacity: 1;
    }
  }

  .authFadeInTop {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: authFadeInTop;
    z-index : 99
  }

  @keyframes authFadeInTop {
    from {
      opacity: 0;
      transform: translateY(25%);
    }
    to {
      opacity: 1;
    }
  }

  .fadeInTop {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeInTop;
  }

  @keyframes fadeInTop {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1;
    }
  }

  .fadeOutTop {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeOutTop;
  }

  @keyframes fadeOutTop {
    from {
      opacity: 0;     
    }
    to {
      opacity: 1;
      transform: translateY(-120%);
    }
  }

  .fadeOutLeft{
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeOutLeft;
  }

  @keyframes fadeOutLeft {
    from {
      opacity: 0;     
    }
    to {
      opacity: 1;
      transform: translateX(-100%);
    }
  }

  .fadeInRightProd {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: fadeInRightProd;
  }

  @keyframes fadeInRightProd {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
    }
  }

  .fadeInBottom {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInBottom;
  }

  @keyframes fadeInBottom {
    from {
      opacity: 0;
      width: 0px !important;
      ${"" /* transform: translateY(-100%); */}
    }
    to {
      opacity: 1;
    }
  }
  .fadeInLeft {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInLeft;
  }

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
    }
  }
  
  .fadeInLeftWidth {
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: both;
    animation-name: fadeInLeftWidth;
  }

  @keyframes fadeInLeftWidth{
    from {
      opacity: 0;
      width: 50%;
    }
    to {
      opacity: 1;
      width: 100%;
    }
  }

  .fadeInRight {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInRight;
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(30px, 30px, 8px);
    }
    to {
      opacity: 1;
    }
  }

  .fadeInHideRight {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInHideRight;
  }

  @keyframes fadeInHideRight {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      height: 0px;
    }
  }

  .fadeInScaleInfinite {
    transform: scale(1);
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-name: fadeInScaleInfinite;
  }

  @keyframes fadeInScaleInfinite {
    from {
      // opacity: 0;
      transform: scale(1.3);
    }
    to {
      // opacity: 1;
      transform: scale(1)
    }
  }
  
  .rotateSetting{
    animation: loading 3s linear infinite;
    @keyframes loading {
     0% { 
       transform: rotate(0); 
     }
     100% { 
       transform: rotate(360deg);
     }
    }
  }


  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #000 !important;
  }
  .container-fluid {
    padding: 0 1rem !important;
  }
  ${
    "" /* .card {
  .card-body {
    padding: 1rem 0.5rem !important;
  }
} */
  }
  .smallScreenPageHeader {
    display: none !important;
  }
  @media only screen and (min-width: 550px) {
    .smallScreenPageHeader {
      display: none !important;
    }
  }
  @media only screen and (max-width: 550px) {
    .smallScreenPageHeader {
      display: block !important;
    }
  }
  .reading {
    border: 1px dashed #ff4d4f !important;
    background: #ff4d4f14 !important;
  }

  [class~="modal"] {
    padding-left: 0 !important;
  }
  .Edit-Icon-Antd {
    cursor: pointer;
    margin: 0px 10px;
    svg {
      transition: 0.6s;
      font-size: 20px;
      path:first-child {
        fill: white;
      }
      path {
        fill: grey;
      }
    }
    &:hover {
      svg {
        transform: scale(1.2);
        path {
          fill: grey;
        }
      }
    }
  }
  .info-product-research {
    padding: 0 !important;
    font-size: 11px !important;
  }
.Eye-Icon-Antd, .Copy-Icon-Antd {
  cursor: pointer;
  margin: 0px 10px;
  width: 23px;
  svg {
    transition: 0.6s;
    font-size: 20px;
    path::nth-child(3), path::nth-child(4) {
      fill: #0062ff;
    }
    path::nth-child(2) {
      fill: #91caff;
    }
  }
  &:hover {
    svg {
      transform: scale(1.2);
      path {
        fill: #0062ff;
      }
      path::nth-child(3), path::nth-child(4) {
        fill: #FFF !important;
      }
    }
  }
}
.Download-Icon-Antd {
  cursor: pointer;
  svg {
    transition: 0.6s;
    font-size: 20px;
    color: #00a1ff;
  }
  &:hover {
    svg {
      transform: scale(1.2);
    }
  }
}
.File-Icon-Antd {
  cursor: pointer;
  margin: 0px 10px;
  svg {
    transition: 0.6s;
    font-size: 20px;
    path:first-child {
      fill: white;
    }
    path {
      fill: #7472ee;
    }
  }
  &:hover {
    svg {
      transform: scale(1.2);
      path:first-child {
        fill: #7472ee56;
      }
      path {
        fill: #7472ee;
      }
    }
  }
}
.Delete-Icon-Antd {
  cursor: pointer;
  margin: 0px 10px;
  svg {
    transition: 0.6s;
    font-size: 20px;
    path:first-child {
      fill: white;
    }
    path {
      fill: #ef7fb1;
    }
  }
  &:hover {
    svg {
      transform: scale(1.2);
      path:first-child {
        fill: #ef7fb156;
      }
      path {
        fill: #ef7fb1;
      }
    }
  }
}

.import-button {
    background: #7472ee;
    color: #fff;
    padding: 0 calc(1.5rem + 1px) !important;
    transition: 0.4s;
    height: 40px;
    padding-bottom: 5px !important;
    border: 1px dashed transparent !important;
    &:hover {
      border: 1px dashed #7472ee !important;
      background: transparent !important;
      color: #7472ee;
    }
    .anticon-cloud-download {
      font-size: 20px;
      margin-right: 7px;
      position: relative;
      top: 4px;
    }
  }
  .export-button {
    background: transparent !important;
    padding: 0 calc(1.5rem + 1px) !important;
    color: green !important;
    transition: 0.4s;
    height: 40px;
    padding-bottom: 2px !important;
    border: 1px dashed lightgreen !important;
    &:hover {
      color: darkgreen !important;
      background-color: lightgreen !important;
    }
    .anticon-cloud-upload {
      font-size: 20px;
      margin-right: 7px;
      position: relative;
      top: 4px;
    }
}
.ant-table-cell-row-hover {
  background: #fafafa !important;
}
.asinImageScale {
  width: 50px;
  height : 50px; 
  // object-fit : contain;
  pointer-events: auto;
  transition : opacity .3s , transform 0.5s !important;
}
.asinImageScale:hover{
  transform: scale(1.5);
}
.ant-rate {color : #fa7414 !important}
.whole-sales-import-file-list {
  .anticon-delete {
    display: none !important;
  }
}
.setting-btn-light-primary{
  background-color : #ededfd;
  color:#7472ee !important
}
.object-fit {
object-fit: contain;
}
.ant-image-mask {
  
  background: rgb(0 0 0 / 0%) !important;
  &:hover {
    color: grey;
    background: rgb(255 255 255 / 69%);
    border: 1px dashed grey;
  }
}
.btn-disable-primary{
  background-color : #b2aef5 !important;
  pointer-events: none;
}
.header_input_disable{
  background-color : #e5e4e7 !important;
}
.modal-open > #root { filter: blur(5px); }
#kt_content_container {
  // padding: 0px 15px 0px 5px !important; 
}
.loadMore{
  animation: sdb03 2s;
}
@keyframes sdb03 {
  30% {
    opacity: 1; 
  }
  60% {
    box-shadow: 0 0 0 60px rgba(0,0,0,.2);
  }
}

.loader-line {
  width: 100%;
  height: 3px;
  position: relative;
  overflow: hidden;
  background-color: #fff;

}

.loader-line:before {
  content: "";
  position: absolute;
  height: 3px;
  width: 40%;
  background-color: #7472ee;
  animation: lineAnim 1s linear infinite;
}

@keyframes lineAnim {
  0% {
      left: -40%;
  }
  50% {
      left: 20%;
      width: 80%;
  }
  100% {
      left: 100%;
      width: 100%;
  }
}
.dashboardProfitScroll {
  height: 665px;
  overflow-y: scroll;
}
#dashboard-product-card{
  height: 200px;
  overflow-y: scroll;
}

.companyEmpScroll{
  height: 285px;
    overflow-y: scroll;
}
.priorityColorDot{
  height:10px;
  width:10px;
  border-radius:5px;
}
.hoverRotateSetting{
 font-size:1.5rem !important;
 color:#7e78ef !important;
}
.hoverRotateSetting:hover{
  animation: loading 3s linear infinite;
  @keyframes loading {
   0% { 
     transform: rotate(0); 
   }
   100% { 
     transform: rotate(360deg);
   }
  }
}
input[type="password"] {
  // font-size: 16px; /* Set an appropriate font size */
  // max-height: 100vh; /* Prevent the page from scrolling on focus */
  // text-size-adjust: 100%; /* Disable text size adjustment */
  touch-action: manipulation; /* Improve touch interaction */
}
.ant-modal-confirm-btns {
  display: flex !important;
  align-items: center !important;
  justify-content: end !important;
  margin-top: 30px !important;
}
.ant-modal-confirm-btns .ant-btn {
  display: flex !important;
  align-items: center !important;
}
.ant-modal-confirm-content {
  width: 100%;
}
.ant-modal-confirm-body {
  height:30px;
  display: flex;
  justify-content: center;
  align-items: flex-start !important;
  flex-wrap: nowrap !important;
}
.ant-modal-confirm .ant-modal-confirm-body >.anticon {
  font-size: 20px;
  margin-top:2px !important;
}

.userProfileNotFound{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #7e78ef;
    border-radius: 10px;
    color: #ffffff;
    font-weight: 400;
    // box-shadow: rgba(0, 0, 0, 0.35) 0px -50px 36px -28px inset;
    box-shadow: 0 15px 25px -4px rgb(198,198,198), inset 0 -3px 4px -1px rgba(0, 0, 0, 0.2), 0 -10px 15px -1px rgba(255, 255, 255, 0.6), inset 0 3px 4px -1px rgba(255, 255, 255, 0.2), inset 0 0 5px 1px rgba(255, 255, 255, 0.8), inset 0 20px 30px 0 rgba(255, 255, 255, 0.2);
}

.searchMainHeaderInput{
  color: #878c92;
  font-size: 19px;
}
.btn-close:focus {
  outline: 0;
  box-shadow:none;
  opacity: 1;
}
.ant-table-expanded-row > .ant-table-cell{
  background:#fff !important;
}
// .ant-table-sticky-holder{
//   top:95px !important;
// }


.commonTableColumnAlign > div{
  display:flex !important;
  justify-content :flex-start !important;
}
.my-40 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important
}
 .ant-select-selector, .ant-picker-range, .ant_common_input, .ant-input-group {
  background-color: #dc725d17 !important;
  border-radius: 0.65rem !important;
  border: 1px solid #5f1a46 !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #5f1a46 !important;

}
#kt_content {

}
#kt_wrapper {
  // padding-left: 10px;
}
progress.progress3::-webkit-progress-bar {
  background-color: #eee; /* Light gray background */
}

/* Styling for the value part of the progress bar */
progress.progress3::-webkit-progress-value {
  background-color: #4CAF50; /* Green color for the filled part */
}

/* For Firefox */
progress.progress3::-moz-progress-bar { 
  background-color: #4CAF50; /* Green color for the filled part */
}
.card {
  border-radius: 0.625rem !important; 
  border: 1px solid #F1F1F4 !important;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03) !important;
  .card-title {
    font-size: 1.1rem !important;
    color: #252f4a!important;
    font-weight: 600!important;
  }
}
.back-button {
  position: absolute;
  z-index: 999;
  background: #f4f7fe;
  padding: 10px;
  border-radius: 20px;
  cursor: pointer;
}
.next-btn{
  position: absolute;
    z-index: 9;
    color: rgb(255, 255, 255);
    background: rgba(255, 255, 255, 0.07);
    height: 100px;
    display: flex;
    align-items: center;
    padding: 0px 8px;
    right: 0px;
    cursor: pointer;
    top: 0px;
    &:hover {
      background: rgba(255, 255, 255, 0.09);
    }
}
.prev-button{
  position: absolute;
  z-index: 9;
  color: rgb(255, 255, 255);
  background: rgba(255, 255, 255, 0.07);
  height: 100px;
  display: flex;
  align-items: center;
  padding: 0px 8px;
  cursor: pointer;
  &:hover {
    background: rgba(255, 255, 255, 0.09);
  }
}

.ant-upload-drag-container {
  zoom: 80%;
  padding: 0px 30px;
}
.ant-upload-list-item-name {
  width: 10px;
}
.ant-upload-list {
  // max-height: 100px;
  overflow: auto;
}
#client-management, #Add_New_Project{
  .ant-input, .ant-input-number-input {
    background: #fcf3f1;
    border: 1px solid #5f1a46;
  }
}
.ant-input {
  border-color: #5f1a46 !important;
  background-color: #fdf3f1 !important;
}
.two-line {
  display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
overflow: hidden;
text-overflow: ellipsis;
}

.fadeInBottom { 
  animation-name: fadeInBottom;
  animation-duration: 1s;
    animation-fill-mode: both;
 }
@keyframes fadeInBottom {
    from {
        opacity: 0;
        transform: translateY(25%);
    }
    to { opacity: 1 }
}
.table-row-light {
  .ant-table-cell:last-child {

    
    // border: 2px solid red !important;
    // border-bottom: none !important;
  }
}
.highcharts-credits {
  display: none;
}

.ant-select-selection-item {
  text-transform: capitalize !important;
}
.fix-over {
  position: relative;
  z-index: 99;
}
.ant-btn-primary {
  background-color: rgb(202 105 90) !important;
}
.svg-icon.svg-icon-primary svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: #ca695a !important;
}
  .ant-select-selection-placeholder {
        color: #beb7b5 !important;  
  }
`;
export default AppStyleWrapper;
