import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OverView from "../../modules/pages/catalogue/over-view";
import {
  GetListCatalogAction,
  GetListCatalogConfigAction,
  UpdateCatalogConfigAction,
  CatalogManualMappingAction,
  SalesChannelListAction,
  GetCatalogOverviewAction,
  getCategoryCatalogOverviewAction,
  fakeActionCatalog,
} from "../../redux/module_/catalog/index.action";

const mapStateToProps = (state) => ({
  CatalogResponse: state.Catalog.CatalogResponse,
  GetCatalogConfigResponse: state.Catalog.GetCatalogConfigResponse,
  UpdateCatalogConfigResponse: state.Catalog.UpdateCatalogConfigResponse,
  CatalogManualMappingResponse: state.Catalog.CatalogManualMappingResponse,
  SalesChannelListResponse: state.Catalog.SalesChannelListResponse,
  GetCatelogOverviewResponse: state.Catalog.GetCatelogOverviewResponse,
  GetCategoryCatalogOverviewResponse:
    state.Catalog.GetCategoryCatalogOverviewResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetListCatalogAction,
      GetListCatalogConfigAction,
      UpdateCatalogConfigAction,
      CatalogManualMappingAction,
      SalesChannelListAction,
      GetCatalogOverviewAction,
      fakeActionCatalog,
      getCategoryCatalogOverviewAction,
    },
    dispatch
  );

const OverView_ = connect(mapStateToProps, mapDispatchToProps)(OverView);

export default OverView_;
