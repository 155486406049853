import axiosCall from "../../configurations/network-services/axiosCall";

export const GetReportsListAction = (url, data) => {
  console.log(data, "data");
  const path = `${url}?sales_channel=${data?.sales_channel || "-"}&pageSize=${
    data?.pageSize || 10
  }&page=${data?.page || 1}&start_date=${data?.start_date || ""}&end_date=${
    data?.end_date || ""
  }&daterange_type=${data?.daterange_type || ""}`;
  const responseType = "GET_ALL_REPORTS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionReports = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_REPORTS", state: data });
};
